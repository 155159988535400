
import about from '../../assets/images/Group 1419.svg';
import style from './Style/Section1.module.css'
import { useNavigate} from 'react-router-dom';
import { useState, useEffect, useRef  } from 'react';


export default function Section1_2() {

  const navigate = useNavigate();

  const navigateMap = () => {
    // 👇️ navigate to /map
    navigate('/map');
  };


  
  const section1 = useRef(null);
  const [isVisibleSection1, setIsVisibleSection1] = useState(false);
  useEffect(() => {
    const observer1 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisibleSection1(true);
          observer1.unobserve(entry.target);
        }
      });
    });
    const sectionCurrent = section1.current;
    if (sectionCurrent) {
      observer1.observe(sectionCurrent);
    }

    return () => {
      if (sectionCurrent) {
        observer1.unobserve(sectionCurrent);
      }
    };
  }, []);



    return (
      <>
        <section ref={section1} class={`${isVisibleSection1 ? style.aboutSectionDesktop : style.section2LaptopVeisuble}`}>
          <div class={"grid grid-cols-2"}>
            <div class={style.aboutSection+ " flex items-center"}>
              <img src={about} alt='About aev'/>  
            </div>
            <div>
              <div class={style.divSectionAbout}>
                  <span>ABOUT AEV</span>
                  <div class={"w-full "}>
                    <h1 class={"text-end font-bold"}>
                      <span class={"font-extrabold"}>مستقبل AEV</span> <br></br>
                      <span class={"font-extrabold"}>مدرسة السياقة</span>
                    </h1>
                    <p class={style.paragraphDiv}>التطبيق الهاتفي التعلمي للمرشحين، هو الجزء الخاص بشق التكوين النظري في تعليم السياقة بمنظومة AEV المتكاملة حيث يلعب دور الوسيط للربط بشكل رقمي بين المرشح و مؤسسة تعليم السياقة من أجل تمكين المدارس من تقديم تدريب عالي الجودة يدعم التعلم المخصص والتعلم عن بعد حيث يمكن لكل طالب بدء التعلم تتبع تقدمه من طرف المدرب وتحليل المهارات لكشف نِقَاط الضعف حسب التصنيفات  أو أو الإطلاع على جدولة الحصص باستخدام تطبيق الهاتف فقط.  </p>
                    <div>
                      <h3 class={"text-end font-extrabold"}>
                      AEV استكشف مدارس السياقة التي تتوفر على
                      </h3>
                      <button class={style.mapBtn} onClick={navigateMap}>
                        <div class={"grid grid-cols-2"}>
                          <div><svg class={style.svgMap} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#fff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M11.3856 23.789L11.3831 23.7871L11.3769 23.7822L11.355 23.765C11.3362 23.7501 11.3091 23.7287 11.2742 23.7008C11.2046 23.6451 11.1039 23.5637 10.9767 23.4587C10.7224 23.2488 10.3615 22.944 9.92939 22.5599C9.06662 21.793 7.91329 20.7041 6.75671 19.419C5.60303 18.1371 4.42693 16.639 3.53467 15.0528C2.64762 13.4758 2 11.7393 2 10C2 7.34784 3.05357 4.8043 4.92893 2.92893C6.8043 1.05357 9.34784 0 12 0C14.6522 0 17.1957 1.05357 19.0711 2.92893C20.9464 4.8043 22 7.34784 22 10C22 11.7393 21.3524 13.4758 20.4653 15.0528C19.5731 16.639 18.397 18.1371 17.2433 19.419C16.0867 20.7041 14.9334 21.793 14.0706 22.5599C13.6385 22.944 13.2776 23.2488 13.0233 23.4587C12.8961 23.5637 12.7954 23.6451 12.7258 23.7008C12.6909 23.7287 12.6638 23.7501 12.645 23.765L12.6231 23.7822L12.6169 23.7871L12.615 23.7885C12.615 23.7885 12.6139 23.7894 12 23L12.6139 23.7894C12.2528 24.0702 11.7467 24.0699 11.3856 23.789ZM12 23L11.3856 23.789C11.3856 23.789 11.3861 23.7894 12 23ZM15 10C15 11.6569 13.6569 13 12 13C10.3431 13 9 11.6569 9 10C9 8.34315 10.3431 7 12 7C13.6569 7 15 8.34315 15 10Z" fill="#fff"></path> </g></svg></div>
                          <div class={style.aevMap}>AEV Map</div>
                        </div>
                      </button>
                    </div>                  
                  </div>
              </div>
            </div>
          </div>
        </section>
        <section class={style.aboutSectionMobile} style={{display:'none'}}>
          <div class={style.aboutSectionDiv}>
              <span>ABOUT AEV</span>
              <h1>مستقبل AEV<br></br>مدرسة السياقة</h1>
          </div>
          <div style={{textAlign:"-webkit-center"}}>
            <img src={about} alt="path" class={style.aboutImg} />
          </div>
          <p class={style.aboutParagraph}>التطبيق الهاتفي التعلمي للمرشحين، هو الجزء الخاص بشق التكوين النظري في تعليم السياقة بمنظومة AEV المتكاملة حيث يلعب دور الوسيط للربط بشكل رقمي بين المرشح و مؤسسة تعليم السياقة من أجل تمكين المدارس من تقديم تدريب عالي الجودة يدعم التعلم المخصص والتعلم عن بعد حيث يمكن لكل طالب بدء التعلم تتبع تقدمه من طرف المدرب وتحليل المهارات لكشف نِقَاط الضعف حسب التصنيفات  أو أو الإطلاع على جدولة الحصص باستخدام تطبيق الهاتف فقط.</p>

          <div class='text-center'>
            <span class={style.headtingSpan}>
             استكشف مدارس السياقة التي <br /> AEV تتوفر على 
            </span>
          </div>
          <div class={"flex justify-center"}>
            <button class={style.mapBtn} onClick={navigateMap}>
              <div class={"grid grid-cols-2"}>
                <div><svg class={style.svgMap} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#fff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M11.3856 23.789L11.3831 23.7871L11.3769 23.7822L11.355 23.765C11.3362 23.7501 11.3091 23.7287 11.2742 23.7008C11.2046 23.6451 11.1039 23.5637 10.9767 23.4587C10.7224 23.2488 10.3615 22.944 9.92939 22.5599C9.06662 21.793 7.91329 20.7041 6.75671 19.419C5.60303 18.1371 4.42693 16.639 3.53467 15.0528C2.64762 13.4758 2 11.7393 2 10C2 7.34784 3.05357 4.8043 4.92893 2.92893C6.8043 1.05357 9.34784 0 12 0C14.6522 0 17.1957 1.05357 19.0711 2.92893C20.9464 4.8043 22 7.34784 22 10C22 11.7393 21.3524 13.4758 20.4653 15.0528C19.5731 16.639 18.397 18.1371 17.2433 19.419C16.0867 20.7041 14.9334 21.793 14.0706 22.5599C13.6385 22.944 13.2776 23.2488 13.0233 23.4587C12.8961 23.5637 12.7954 23.6451 12.7258 23.7008C12.6909 23.7287 12.6638 23.7501 12.645 23.765L12.6231 23.7822L12.6169 23.7871L12.615 23.7885C12.615 23.7885 12.6139 23.7894 12 23L12.6139 23.7894C12.2528 24.0702 11.7467 24.0699 11.3856 23.789ZM12 23L11.3856 23.789C11.3856 23.789 11.3861 23.7894 12 23ZM15 10C15 11.6569 13.6569 13 12 13C10.3431 13 9 11.6569 9 10C9 8.34315 10.3431 7 12 7C13.6569 7 15 8.34315 15 10Z" fill="#fff"></path> </g></svg></div>
                <div class={style.aevMap}>AEV Map</div>
              </div>
            </button>
          </div>
        </section>



      </>
    );

}
  
