import page3_path6 from '../../../assets/images/expert2.png';

import cardPage1 from '../../../assets/images/cardPage1.png';
import cardPage2 from '../../../assets/images/cardPage2.png';
import cardPage3 from '../../../assets/images/cardPage3.png';

import statics from '../../../assets/images/DesktopPath.png';

import style from './Style/Section2.module.css';
// import { useState, useRef } from 'react';
import { useState, useEffect, useRef  } from 'react';

import { FreeMode, Pagination } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';


// Import Swiper styles
import "swiper/css";
import "./Style/slider.css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

function Section2() {
    const [slidesPerView, setSlidesPerView] = useState(3);

    useEffect(() => {
      const handleWindowResize = () => {
        if (window.innerWidth <= 1390) {setSlidesPerView(2)}
        else{setSlidesPerView(3)}
        if (window.innerWidth <= 930) {setSlidesPerView(1)}
      };
      handleWindowResize()
      window.addEventListener('resize', handleWindowResize);
    },[]);


     const swiperRef = useRef(null);

  const handleSwiperInit = (swiper) => {
    swiperRef.current = swiper;
  };

  const handleNextSlide = () => {
    swiperRef.current.slideNext();
  };
  const handlePrevSlide = () => {
    swiperRef.current.slidePrev();
  };


  const section1 = useRef(null);
  const section2 = useRef(null);
  const section3 = useRef(null);
  const section4 = useRef(null);
  const [isVisibleSection1, setIsVisibleSection1] = useState(false);
  const [isVisibleSection2, setIsVisibleSection2] = useState(false);
  const [isVisibleSection3, setIsVisibleSection3] = useState(false);
  const [isVisibleSection4, setIsVisibleSection4] = useState(false);
  useEffect(() => {
    const observer1 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisibleSection1(true);
          observer1.unobserve(entry.target);
        }
      });
    });
    const observer2 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisibleSection2(true);
          observer2.unobserve(entry.target);
        }
      });
    });
    const observer3 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisibleSection3(true);
          observer3.unobserve(entry.target);
        }
      });
    });
    const observer4 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisibleSection4(true);
          observer4.unobserve(entry.target);
        }
      });
    });

    const sectionCorrent1 = section1.current;
    const sectionCorrent2 = section2.current;
    const sectionCorrent3 = section3.current;
    const sectionCorrent4 = section4.current;

    if (sectionCorrent1) {
      observer1.observe(sectionCorrent1);
    }

    if (sectionCorrent2) {
      observer2.observe(sectionCorrent2);
    }
    if (sectionCorrent3) {
      observer3.observe(sectionCorrent3);
    }
    if (sectionCorrent4) {
      observer4.observe(sectionCorrent4);
    }

    return () => {
      if (sectionCorrent1) {
        observer1.unobserve(sectionCorrent1);
      }
      if (sectionCorrent2) {
        observer2.unobserve(sectionCorrent2);
      }
      if (sectionCorrent3) {
        observer3.unobserve(sectionCorrent3);
      }
      if (sectionCorrent4) {
        observer4.unobserve(sectionCorrent4);
      }
    };
  }, []);




    return (
      <>
        <section ref={section1} class={`${isVisibleSection1 ? style.section2Laptop : style.section2LaptopVeisuble} px-2 py-19 md:px-0`} style={{margin:"100px 0 100px 0"}}>
          <div class="grid grid-cols-3 gap-4">
            <div class="place-self-center">
              <div class={style.parent}>
              <img  class={style.image1} src={page3_path6} alt='phoneimage1'/>
              {/* <img class={style.image2} src={page3_path1}  alt='phoneimage2'/>
              <img class={style.image3} src={page3_path2}  alt='phoneimage2'/>
              <img class={style.image4} src={page3_path3}  alt='phoneimage3'/>
              <img class={style.image6} src={page3_path5}  alt='phoneimage3'/> */}

              </div>
            </div>
            <div class="place-self-center">
              <span class={style.paragraphSection2}>
                التطبيق الهاتفي التعلمي للمرشحين، هو الجزء الخاص بشق التكوين النظري في تعليم السياقة بمنظومة AEV المتكاملة
              </span>

            </div>
            <div class="place-self-center">
              <div class={style.divCenter}>
                <div class={style.divHeader}>
                  <h1 class={style.divH1}>التطبيق الهاتفي </h1><span class={style.divSpan}> AEV</span>
                </div>
                <h1 class={style.divH1second}>
                  <span class="xl:inline">مستقبل AEV</span> <br></br><span class="block xl:inline">مدرسة السياقة</span>
                </h1>
              </div>
            </div>
          </div>
        </section>
        <section class={style.section2Mobile} style={{display:"none"}}>
          <div class={style.divHeadingMobile}>
            <span>تطبيق هاتفي AEV</span>
            <h1>مستقبل AEV <br></br > مدرسة السياقة</h1>
            <p>التطبيق الهاتفي التعلمي للمرشحين، هو الجزء الخاص بشق التكوين النظري في تعليم السياقة بمنظومة AEV المتكاملة</p>
          </div>
          <div style={{textAlign:"-webkit-center"}}>
            <div class={style.parent}>
                <img class={style.image1} src={statics} alt='phoneimage1' />
              </div>


          </div>
        </section>

        {/* --------------------------------- SECTION 3 ------------------------------------- */}
        <section ref={section2} class={`${isVisibleSection2 ? style.section3Desktop : style.section2LaptopVeisuble}`}>
          <div class="grid grid-cols-2">
            <div class={style.textalign}>
              <a href="/" class="items-end flex text-center pt-2 pb-1">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1000 1000"
                  enable-background="new 0 0 1000 1000" class={style.svgSection}>
                  <g>
                    <path
                      d="M66.5,443.3h866.9c31.2,0,56.5,25.3,56.5,56.5c0,31.2-25.3,56.5-56.5,56.5H66.5C35.3,556.3,10,531,10,499.8C10,468.6,35.3,443.3,66.5,443.3z" />
                    <path
                      d="M469.7,96.6c23.9,23.9,28.9,57.6,11.2,75.3L128.6,524.3C110.9,542,50,563.2,26.1,539.4s-18.3-60.8-0.6-78.5L394.5,85.4C412.1,67.7,445.9,72.7,469.7,96.6z" />
                    <path
                      d="M66.5,443.3h866.9c31.2,0,56.5,25.3,56.5,56.5c0,31.2-25.3,56.5-56.5,56.5H66.5C35.3,556.3,10,531,10,499.8C10,468.6,35.3,443.3,66.5,443.3z" />
                    <path
                      d="M394.5,914.6L25.5,539.1c-17.7-17.7-23.3-54.6,0.6-78.5s84.7-2.6,102.4,15.1L481,828.1c17.7,17.7,12.7,51.4-11.2,75.3C445.9,927.3,412.1,932.3,394.5,914.6z" />
                  </g>
                </svg>
                <span class={style.svgSpan}>استكشف كل الميزات</span>
              </a>
            </div>
            <div class="place-self-center">
              <div class="top flex p-2 select-none">
                <div class={style.buttons + " ml-auto flex mr-1" }>
                  <div>
                    <a href="/" class={style.sectionHref}>
                      <span class={style.sectionHrefSpan}>المحاكي</span>
                    </a>
                  </div>
                  <div>
                    <a href="/" class={style.sectionHrefHovering}>
                      <span class={style.sectionHrefSpan}>المنصة</span>
                    </a>
                  </div>
                  <div>
                    <a href="/" class={style.sectionHref}>
                      <span class={style.sectionHrefSpan}>التطبيق</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class={style.section3Mobile} style={{display:"none"}}>
          <div class={style.buttons}>
            <button class={style.btn1Section2}><span>المحاكي</span></button>
            <button class={style.btn1Section}><span>المنصة</span></button>
            <button class={style.btn1Section2}><span>التطبيق</span></button>
          </div>
        </section>

        {/* --------------------------------- SECTION 4 ------------------------------------- */}
        <section ref={section3} class={`${isVisibleSection3 ? style.section4Desktop : style.section2LaptopVeisuble}`}>
          <Swiper spaceBetween={-100} slidesPerView={slidesPerView} freeMode={true}
            modules={[FreeMode, Pagination]} className="mySwiper"  onSwiper={handleSwiperInit}>
            <SwiperSlide>
              <div class={style.divSwiperr}>
                <div class={style.divSection4}>
                  <div class="grid grid-cols-3 gap-4 items-center" style={{padding: '2rem 2rem 0rem 2rem'}}>
                    <div class="col-span-2"><h2 class={style.h2Section4}>محتوى مراقب ومحدث بإستمرار</h2></div>
                    <div class="."> <img src={cardPage1} alt=" Logo" class={style.imgSection4 + " inline-flex items-center justify-center" } /></div>
                  </div>
                  <div class="flex-grow text-end p-8">
                    <p class={style.paragraphSection4}>في الجودة هو هدفنا الرئيسي ، تتم مراقبة ومراجعة جميع محتويات الدروس والسلاسل باستمرار التحقق من جدواها ومطابقتها </p>
                    <a href="/">
                      <div class="flex items-center justify-end">
                          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1000 1000"
                            enable-background="new 0 0 1000 1000" class={style.svgParagraph} fill='#1f0d3c'>
                            <g>
                              <path
                                d="M66.5,443.3h866.9c31.2,0,56.5,25.3,56.5,56.5c0,31.2-25.3,56.5-56.5,56.5H66.5C35.3,556.3,10,531,10,499.8C10,468.6,35.3,443.3,66.5,443.3z" />
                              <path
                                d="M469.7,96.6c23.9,23.9,28.9,57.6,11.2,75.3L128.6,524.3C110.9,542,50,563.2,26.1,539.4s-18.3-60.8-0.6-78.5L394.5,85.4C412.1,67.7,445.9,72.7,469.7,96.6z" />
                              <path
                                d="M66.5,443.3h866.9c31.2,0,56.5,25.3,56.5,56.5c0,31.2-25.3,56.5-56.5,56.5H66.5C35.3,556.3,10,531,10,499.8C10,468.6,35.3,443.3,66.5,443.3z" />
                              <path
                                d="M394.5,914.6L25.5,539.1c-17.7-17.7-23.3-54.6,0.6-78.5s84.7-2.6,102.4,15.1L481,828.1c17.7,17.7,12.7,51.4-11.2,75.3C445.9,927.3,412.1,932.3,394.5,914.6z" />
                            </g>
                          </svg>
                          <span class={style.divSpanSection4}>اقرأ المزيد</span>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class={style.divSwiperr}>
                <div class={style.divSection4}>
                  <div class="grid grid-cols-3 gap-4 items-center" style={{padding: '2rem 2rem 0rem 2rem'}}>
                    <div class="col-span-2"><h2 class={style.h2Section4}>محتوى مراقب ومحدث بإستمرار</h2></div>
                    <div class="."> <img src={cardPage2} alt=" Logo" class={style.imgSection4 + " inline-flex items-center justify-center" } /></div>
                  </div>
                  <div class="flex-grow text-end p-8">
                    <p class={style.paragraphSection4}>في الجودة هو هدفنا الرئيسي ، تتم مراقبة ومراجعة جميع محتويات الدروس والسلاسل باستمرار التحقق من جدواها ومطابقتها </p>
                    <a href="/">
                      <div class="flex items-center justify-end">
                          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1000 1000"
                            enable-background="new 0 0 1000 1000" class={style.svgParagraph} fill='#1f0d3c'>
                            <g>
                              <path
                                d="M66.5,443.3h866.9c31.2,0,56.5,25.3,56.5,56.5c0,31.2-25.3,56.5-56.5,56.5H66.5C35.3,556.3,10,531,10,499.8C10,468.6,35.3,443.3,66.5,443.3z" />
                              <path
                                d="M469.7,96.6c23.9,23.9,28.9,57.6,11.2,75.3L128.6,524.3C110.9,542,50,563.2,26.1,539.4s-18.3-60.8-0.6-78.5L394.5,85.4C412.1,67.7,445.9,72.7,469.7,96.6z" />
                              <path
                                d="M66.5,443.3h866.9c31.2,0,56.5,25.3,56.5,56.5c0,31.2-25.3,56.5-56.5,56.5H66.5C35.3,556.3,10,531,10,499.8C10,468.6,35.3,443.3,66.5,443.3z" />
                              <path
                                d="M394.5,914.6L25.5,539.1c-17.7-17.7-23.3-54.6,0.6-78.5s84.7-2.6,102.4,15.1L481,828.1c17.7,17.7,12.7,51.4-11.2,75.3C445.9,927.3,412.1,932.3,394.5,914.6z" />
                            </g>
                          </svg>
                          <span class={style.divSpanSection4}>اقرأ المزيد</span>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class={style.divSwiperr}>
                <div class={style.divSection4 +" "+ style.divSection4_2}>
                  <div class="grid grid-cols-3 gap-4 items-center" style={{padding: '2rem 2rem 0rem 2rem'}}>
                    <div class="col-span-2"><h2 class={style.h2Section4 + " " + style.h2Section4_2}>محتوى مراقب ومحدث بإستمرار</h2></div>
                    <div class="."> <img src={cardPage3} alt=" Logo" class={style.imgSection4 + " inline-flex items-center justify-center" } /></div>
                  </div>
                  <div class="flex-grow text-end p-8">
                    <p class={style.paragraphSection4 + " " + style.paragraphSection4Active}>في الجودة هو هدفنا الرئيسي ، تتم مراقبة ومراجعة جميع محتويات الدروس والسلاسل باستمرار التحقق من جدواها ومطابقتها </p>
                    <a href="/">
                      <div class="flex items-center justify-end">
                          <svg  version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1000 1000"
                            enable-background="new 0 0 1000 1000" class={style.svgParagraph + " " + style.svgParagraphActiveh} fill='#1f0d3c'>
                            <g>
                              <path
                                d="M66.5,443.3h866.9c31.2,0,56.5,25.3,56.5,56.5c0,31.2-25.3,56.5-56.5,56.5H66.5C35.3,556.3,10,531,10,499.8C10,468.6,35.3,443.3,66.5,443.3z" />
                              <path
                                d="M469.7,96.6c23.9,23.9,28.9,57.6,11.2,75.3L128.6,524.3C110.9,542,50,563.2,26.1,539.4s-18.3-60.8-0.6-78.5L394.5,85.4C412.1,67.7,445.9,72.7,469.7,96.6z" />
                              <path
                                d="M66.5,443.3h866.9c31.2,0,56.5,25.3,56.5,56.5c0,31.2-25.3,56.5-56.5,56.5H66.5C35.3,556.3,10,531,10,499.8C10,468.6,35.3,443.3,66.5,443.3z" />
                              <path
                                d="M394.5,914.6L25.5,539.1c-17.7-17.7-23.3-54.6,0.6-78.5s84.7-2.6,102.4,15.1L481,828.1c17.7,17.7,12.7,51.4-11.2,75.3C445.9,927.3,412.1,932.3,394.5,914.6z" />
                            </g>
                          </svg>
                          <span class={style.divSpanSection4 + " " + style.divSpanSection4Active2}>اقرأ المزيد</span>
                      </div>
                    </a>
                  </div>
                </div>
              </div>

            </SwiperSlide>
            <SwiperSlide>
              <div class={style.divSwiperr}>
                <div class={style.divSection4}>
                  <div class="grid grid-cols-3 gap-4 items-center" style={{padding: '2rem 2rem 0rem 2rem'}}>
                    <div class="col-span-2"><h2 class={style.h2Section4}>محتوى مراقب ومحدث بإستمرار</h2></div>
                    <div class="."> <img src={cardPage1} alt=" Logo" class={style.imgSection4 + " inline-flex items-center justify-center" } /></div>
                  </div>
                  <div class="flex-grow text-end p-8">
                    <p class={style.paragraphSection4}>في الجودة هو هدفنا الرئيسي ، تتم مراقبة ومراجعة جميع محتويات الدروس والسلاسل باستمرار التحقق من جدواها ومطابقتها </p>
                    <a href="/">
                      <div class="flex items-center justify-end">
                          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1000 1000"
                            enable-background="new 0 0 1000 1000" class={style.svgParagraph} fill='#1f0d3c'>
                            <g>
                              <path
                                d="M66.5,443.3h866.9c31.2,0,56.5,25.3,56.5,56.5c0,31.2-25.3,56.5-56.5,56.5H66.5C35.3,556.3,10,531,10,499.8C10,468.6,35.3,443.3,66.5,443.3z" />
                              <path
                                d="M469.7,96.6c23.9,23.9,28.9,57.6,11.2,75.3L128.6,524.3C110.9,542,50,563.2,26.1,539.4s-18.3-60.8-0.6-78.5L394.5,85.4C412.1,67.7,445.9,72.7,469.7,96.6z" />
                              <path
                                d="M66.5,443.3h866.9c31.2,0,56.5,25.3,56.5,56.5c0,31.2-25.3,56.5-56.5,56.5H66.5C35.3,556.3,10,531,10,499.8C10,468.6,35.3,443.3,66.5,443.3z" />
                              <path
                                d="M394.5,914.6L25.5,539.1c-17.7-17.7-23.3-54.6,0.6-78.5s84.7-2.6,102.4,15.1L481,828.1c17.7,17.7,12.7,51.4-11.2,75.3C445.9,927.3,412.1,932.3,394.5,914.6z" />
                            </g>
                          </svg>
                          <span class={style.divSpanSection4}>اقرأ المزيد</span>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </section>
        <section class={style.section4Mobile} style={{display:"none"}}>
          <Swiper spaceBetween={10} slidesPerView={slidesPerView} freeMode={true} pagination={{clickable: true}}
            modules={[FreeMode, Pagination]} className="mySwiper">
            <SwiperSlide>
              <div class={style.divSwiperr}>
                <div class={style.divSection4}>
                  <div class="grid grid-cols-3 gap-4 items-center" style={{padding: '2rem 2rem 0rem 2rem'}}>
                    <div class="col-span-2"><h2 class={style.h2Section4}>محتوى مراقب ومحدث بإستمرار</h2></div>
                    <div class="."> <img src={cardPage2} alt=" Logo" class={style.imgSection4 + " inline-flex items-center justify-center" } /></div>
                  </div>
                  <div class="flex-grow text-end p-8">
                    <p class={style.paragraphSection4}>في الجودة هو هدفنا الرئيسي ، تتم مراقبة ومراجعة جميع محتويات الدروس والسلاسل باستمرار التحقق من جدواها ومطابقتها </p>
                    <a href="/">
                      <div class="flex items-center justify-end">
                          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1000 1000"
                            enable-background="new 0 0 1000 1000" class={style.svgParagraph} fill='#1f0d3c'>
                            <g>
                              <path
                                d="M66.5,443.3h866.9c31.2,0,56.5,25.3,56.5,56.5c0,31.2-25.3,56.5-56.5,56.5H66.5C35.3,556.3,10,531,10,499.8C10,468.6,35.3,443.3,66.5,443.3z" />
                              <path
                                d="M469.7,96.6c23.9,23.9,28.9,57.6,11.2,75.3L128.6,524.3C110.9,542,50,563.2,26.1,539.4s-18.3-60.8-0.6-78.5L394.5,85.4C412.1,67.7,445.9,72.7,469.7,96.6z" />
                              <path
                                d="M66.5,443.3h866.9c31.2,0,56.5,25.3,56.5,56.5c0,31.2-25.3,56.5-56.5,56.5H66.5C35.3,556.3,10,531,10,499.8C10,468.6,35.3,443.3,66.5,443.3z" />
                              <path
                                d="M394.5,914.6L25.5,539.1c-17.7-17.7-23.3-54.6,0.6-78.5s84.7-2.6,102.4,15.1L481,828.1c17.7,17.7,12.7,51.4-11.2,75.3C445.9,927.3,412.1,932.3,394.5,914.6z" />
                            </g>
                          </svg>
                          <span class={style.divSpanSection4}>اقرأ المزيد</span>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class={style.divSwiperr}>
                <div class={style.divSection4}>
                  <div class="grid grid-cols-3 gap-4 items-center" style={{padding: '2rem 2rem 0rem 2rem'}}>
                    <div class="col-span-2"><h2 class={style.h2Section4}>محتوى مراقب ومحدث بإستمرار</h2></div>
                    <div class="."> <img src={cardPage3} alt=" Logo" class={style.imgSection4 + " inline-flex items-center justify-center" } /></div>
                  </div>
                  <div class="flex-grow text-end p-8">
                    <p class={style.paragraphSection4}>في الجودة هو هدفنا الرئيسي ، تتم مراقبة ومراجعة جميع محتويات الدروس والسلاسل باستمرار التحقق من جدواها ومطابقتها </p>
                    <a href="/">
                      <div class="flex items-center justify-end">
                          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1000 1000"
                            enable-background="new 0 0 1000 1000" class={style.svgParagraph} fill='#1f0d3c'>
                            <g>
                              <path
                                d="M66.5,443.3h866.9c31.2,0,56.5,25.3,56.5,56.5c0,31.2-25.3,56.5-56.5,56.5H66.5C35.3,556.3,10,531,10,499.8C10,468.6,35.3,443.3,66.5,443.3z" />
                              <path
                                d="M469.7,96.6c23.9,23.9,28.9,57.6,11.2,75.3L128.6,524.3C110.9,542,50,563.2,26.1,539.4s-18.3-60.8-0.6-78.5L394.5,85.4C412.1,67.7,445.9,72.7,469.7,96.6z" />
                              <path
                                d="M66.5,443.3h866.9c31.2,0,56.5,25.3,56.5,56.5c0,31.2-25.3,56.5-56.5,56.5H66.5C35.3,556.3,10,531,10,499.8C10,468.6,35.3,443.3,66.5,443.3z" />
                              <path
                                d="M394.5,914.6L25.5,539.1c-17.7-17.7-23.3-54.6,0.6-78.5s84.7-2.6,102.4,15.1L481,828.1c17.7,17.7,12.7,51.4-11.2,75.3C445.9,927.3,412.1,932.3,394.5,914.6z" />
                            </g>
                          </svg>
                          <span class={style.divSpanSection4}>اقرأ المزيد</span>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class={style.divSwiperr}>
                <div class={style.divSection4 + " " + style.divSection4Active}>
                  <div class="grid grid-cols-3 gap-4 items-center" style={{padding: '2rem 2rem 0rem 2rem'}}>
                    <div class="col-span-2"><h2 class={style.h2Section4 + " "+style.h2Section4Active}>محتوى مراقب ومحدث بإستمرار</h2></div>
                    <div class="."> <img src={cardPage1} alt=" Logo" class={style.imgSection4 + " inline-flex items-center justify-center" } /></div>
                  </div>
                  <div class="flex-grow text-end p-8">
                    <p class={style.paragraphSection4 + " "+ style.paragraphSection4Active}>في الجودة هو هدفنا الرئيسي ، تتم مراقبة ومراجعة جميع محتويات الدروس والسلاسل باستمرار التحقق من جدواها ومطابقتها </p>
                    <a href="/">
                      <div class="flex items-center justify-end">
                          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1000 1000"
                            enable-background="new 0 0 1000 1000" class={style.svgParagraph+ " "+ style.svgParagraphAcive} fill='#1f0d3c'>
                            <g>
                              <path
                                d="M66.5,443.3h866.9c31.2,0,56.5,25.3,56.5,56.5c0,31.2-25.3,56.5-56.5,56.5H66.5C35.3,556.3,10,531,10,499.8C10,468.6,35.3,443.3,66.5,443.3z" />
                              <path
                                d="M469.7,96.6c23.9,23.9,28.9,57.6,11.2,75.3L128.6,524.3C110.9,542,50,563.2,26.1,539.4s-18.3-60.8-0.6-78.5L394.5,85.4C412.1,67.7,445.9,72.7,469.7,96.6z" />
                              <path
                                d="M66.5,443.3h866.9c31.2,0,56.5,25.3,56.5,56.5c0,31.2-25.3,56.5-56.5,56.5H66.5C35.3,556.3,10,531,10,499.8C10,468.6,35.3,443.3,66.5,443.3z" />
                              <path
                                d="M394.5,914.6L25.5,539.1c-17.7-17.7-23.3-54.6,0.6-78.5s84.7-2.6,102.4,15.1L481,828.1c17.7,17.7,12.7,51.4-11.2,75.3C445.9,927.3,412.1,932.3,394.5,914.6z" />
                            </g>
                          </svg>
                          <span class={style.divSpanSection4+ " "+ style.divSpanSection4Active}>اقرأ المزيد</span>
                      </div>
                    </a>
                  </div>
                </div>
              </div>


            </SwiperSlide>
            <SwiperSlide>
              <div class={style.divSwiperr}>
                <div class={style.divSection4}>
                  <div class="grid grid-cols-3 gap-4 items-center" style={{padding: '2rem 2rem 0rem 2rem'}}>
                    <div class="col-span-2"><h2 class={style.h2Section4}>محتوى مراقب ومحدث بإستمرار</h2></div>
                    <div class="."> <img src={cardPage2} alt=" Logo" class={style.imgSection4 + " inline-flex items-center justify-center" } /></div>
                  </div>
                  <div class="flex-grow text-end p-8">
                    <p class={style.paragraphSection4}>في الجودة هو هدفنا الرئيسي ، تتم مراقبة ومراجعة جميع محتويات الدروس والسلاسل باستمرار التحقق من جدواها ومطابقتها </p>
                    <a href="/">
                      <div class="flex items-center justify-end">
                          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1000 1000"
                            enable-background="new 0 0 1000 1000" class={style.svgParagraph} fill='#1f0d3c'>
                            <g>
                              <path
                                d="M66.5,443.3h866.9c31.2,0,56.5,25.3,56.5,56.5c0,31.2-25.3,56.5-56.5,56.5H66.5C35.3,556.3,10,531,10,499.8C10,468.6,35.3,443.3,66.5,443.3z" />
                              <path
                                d="M469.7,96.6c23.9,23.9,28.9,57.6,11.2,75.3L128.6,524.3C110.9,542,50,563.2,26.1,539.4s-18.3-60.8-0.6-78.5L394.5,85.4C412.1,67.7,445.9,72.7,469.7,96.6z" />
                              <path
                                d="M66.5,443.3h866.9c31.2,0,56.5,25.3,56.5,56.5c0,31.2-25.3,56.5-56.5,56.5H66.5C35.3,556.3,10,531,10,499.8C10,468.6,35.3,443.3,66.5,443.3z" />
                              <path
                                d="M394.5,914.6L25.5,539.1c-17.7-17.7-23.3-54.6,0.6-78.5s84.7-2.6,102.4,15.1L481,828.1c17.7,17.7,12.7,51.4-11.2,75.3C445.9,927.3,412.1,932.3,394.5,914.6z" />
                            </g>
                          </svg>
                          <span class={style.divSpanSection4}>اقرأ المزيد</span>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </SwiperSlide>




          </Swiper>
          <div class="flex justify-center">
            <a href="/" class="items-end flex text-center pt-2 pb-1">
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1000 1000"
                enable-background="new 0 0 1000 1000" class={style.svgSection}>
                <g>
                  <path
                    d="M66.5,443.3h866.9c31.2,0,56.5,25.3,56.5,56.5c0,31.2-25.3,56.5-56.5,56.5H66.5C35.3,556.3,10,531,10,499.8C10,468.6,35.3,443.3,66.5,443.3z" />
                  <path
                    d="M469.7,96.6c23.9,23.9,28.9,57.6,11.2,75.3L128.6,524.3C110.9,542,50,563.2,26.1,539.4s-18.3-60.8-0.6-78.5L394.5,85.4C412.1,67.7,445.9,72.7,469.7,96.6z" />
                  <path
                    d="M66.5,443.3h866.9c31.2,0,56.5,25.3,56.5,56.5c0,31.2-25.3,56.5-56.5,56.5H66.5C35.3,556.3,10,531,10,499.8C10,468.6,35.3,443.3,66.5,443.3z" />
                  <path
                    d="M394.5,914.6L25.5,539.1c-17.7-17.7-23.3-54.6,0.6-78.5s84.7-2.6,102.4,15.1L481,828.1c17.7,17.7,12.7,51.4-11.2,75.3C445.9,927.3,412.1,932.3,394.5,914.6z" />
                </g>
              </svg>
              <span class={style.svgSpan}>استكشف كل الميزات</span>
            </a>
          </div>

        </section>
        {/* --------------------------------- SECTION 5 ------------------------------------- */}
        <section ref={section4} class={`${isVisibleSection4 ? style.section5 : style.section2LaptopVeisuble}`}>
        <div class="grid grid-cols-3 gap-3">
          <div class={style.divFlex + " inline-flex" } role="group">
            <button type="button" class="inline-flex items-center custom-swiper-next" id='nextBtnSwiper' onClick={handlePrevSlide}>
              <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
                <rect width='24' height='24' stroke='none' fill='#000000' opacity='0' />
                <g transform="matrix(0.83 0 0 0.83 12 12)">
                  <path transform=" translate(-13.5, -15)"
                    d="M 17 3 L 19 3 C 19.386 3 19.738 3.223 19.904 3.572 C 20.07 3.9210000000000003 20.019 4.334 19.774 4.634 L 11.292 15 L 19.774 25.367 C 20.019000000000002 25.666 20.069000000000003 26.079 19.904 26.429000000000002 C 19.738999999999997 26.779000000000003 19.386 27 19 27 L 17 27 C 16.7 27 16.416 26.865 16.226 26.633 L 7.225999999999999 15.633 C 6.924999999999999 15.264 6.924999999999999 14.735 7.225999999999999 14.366 L 16.226 3.3659999999999997 C 16.416 3.135 16.7 3 17 3 z"
                    stroke-linecap="round" />
                </g>
              </svg>
            </button>
            <button type="button" class="inline-flex items-center" id='prevBtnSwiper' onClick={handleNextSlide}>
              <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
                <rect width='24' height='24' stroke='none' fill='#000000' opacity='0' />
                <g transform="matrix(0.83 0 0 0.83 12 12)">
                  <path transform=" translate(-15.5, -15)"
                    d="M 12 27 L 10 27 C 9.614 27 9.262 26.777 9.096 26.428 C 8.93 26.079 8.981 25.666 9.226 25.366 L 17.708 15 L 9.226 4.633 C 8.981000000000002 4.334 8.931000000000001 3.9210000000000003 9.096 3.5709999999999997 C 9.261 3.220999999999999 9.614 3 10 3 L 12 3 C 12.3 3 12.584 3.135 12.774000000000001 3.367 L 21.774 14.367 C 22.075 14.736 22.075 15.265 21.774 15.634 L 12.774000000000001 26.634 C 12.584 26.865 12.3 27 12 27 z"
                    stroke-linecap="round" />
                </g>
              </svg>
            </button>
          </div>
          <div></div>
          <div></div>
        </div>
      </section>
      </>
    );
}
  
export default Section2;