import Header from '../partials/header';
import Header2 from '../partials/header/header2';
/* ----------------------------------------------------------- */
import Section1 from '../components/Home/Page1/Section1';
import Section2 from '../components/Home/Page1/Section2';

/* ----------------------------------------------------------- */
import Section12 from '../components/Home/Page2/Section1';
import Section22 from '../components/Home/Page2/Section2';

/* ----------------------------------------------------------- */
import Section13 from '../components/Home/Page3/Section1';
import Section23 from '../components/Home/Page3/Section2';

/* ----------------------------------------------------------- */
import AboutSection1 from '../partials/footer/Section1';
import AboutSection2 from '../partials/footer/section2';
import Footer from '../partials/footer/Footer';

import style from '../components/Home.module.css';
import '../components/Home.module.css';

function App() {

  return (
    <div className={"h-screen snap-y snap-mandatory overflow-x-auto overflow-scroll overflow-hidden"} >
      {/* ------------------------------Page 1------------------------------ */}
        <div className="App" class="section1 snap-start scroll-smooth overflow-hidden" id='app'>
          <div class={style.divGlobalHome + ''}>
            <div class='snap-start'>
              <Header />
            </div>
            <div class='snap-start'>
              <Section1 />
            </div>
          </div>
          <div>
              <Section2 />
          </div>
        </div>


      {/* ------------------------------Page 2------------------------------ */}

        <div className="App snap-start scroll-smooth overflow-hidden"  style={{ backgroundColor: "#1f0d3c" }} id='platform'>
          <div class={style.divGlobalHomePage2 + " "}>
            <div class='snap-start'>
              <Header2 />
            </div>
            <div class="snap-start">
              <Section12 />
            </div>
          </div>
          <div class="snap-start">
            <div>
              <Section22 />
            </div>
          </div>
        </div>
  

      {/* ------------------------------Page 3------------------------------ */}
      <div className="App overflow-hidden" class="snap-start  scroll-smooth" style={{ backgroundColor: "#fee0d5" }} id='simulator'>
        <div class={style.divGlobalHome}>
          <Header />
          <Section13 />
        </div>
        <Section23 />
      </div>

      <div className="App overflow-hidden" class="snap-end scroll-smooth" style={{ backgroundColor: "#f9f6ff" }}>
        <div class="h-screen flex items-center">
          <AboutSection1 />
        </div>
        <AboutSection2 />
        <Footer />
      </div>
    </div>
  );
}

export default App;
