import { useState, useEffect, useRef  } from 'react';

import style from './Style/Section1.module.css';
import { FaPlay } from 'react-icons/fa';
/* -------------------------------------------------------------- */
import Desktop from '../../../assets/images/Desktop.png';
import Desktop2 from '../../../assets/images/Desktop2.png';
import laptopMobileImg from '../../../assets/images/GroupDesktop.png';
import icondown from '../../../assets/images/icondown.png';






function Section1() {

  const [showModal, setShowModal] = useState(false);

  
  const section1 = useRef(null);
  const [isVisibleSection1, setIsVisibleSection1] = useState(false);
  useEffect(() => {
    const observer1 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisibleSection1(true);
          observer1.unobserve(entry.target);
        }
      });
    });
    const correntSection = section1.current;

    if (correntSection) {
      observer1.observe(correntSection);
    }

    return () => {
      if (correntSection) {
        observer1.unobserve(correntSection);
      }
    };
  }, []);
  return (
    <>
      {/* ---------------------------------SECTION 1 ------------------------------------- */}
      <section ref={section1} class={`${isVisibleSection1 ? style.sectionLaptop : style.section2LaptopVeisuble}`}>
        <div class={style.gridSection1 + " grid grid-cols-2" }>
          <div>
            <div class="w-full">
              <div class={style.images}>
                <div class={style.parent}>
                  <img class={style.image2} alt="img2" src={Desktop} />
                  <img class={style.image1} alt="imafe1" src={Desktop2} />
                </div>
              </div>
            </div>
          </div>
          <div class="flex items-center">
            <div class="w-full">
              <div class={style.divH}>
                <div class={style.paddingTitle+" mt-12 flex items-center text-end place-content-end"}>
                  <div class={style.titleHeader}>قم بتشغيل الفيديو لاستكشاف المستقبل</div>
                  <a href='https://www.youtube.com/' class={style.Ellipse}>
                    <div class={style.Ellipse6}>
                      <FaPlay fill='#1f0d3c' />
                    </div>
                  </a>
                </div>
                <div class={style.paddingTitle
                  + " w-full md:space-y-4 lg:space-y-8 xl:space-y-9 sm:pr-6 lg:pr-0 md:pb-0 pr-5" }>
                  <h1 class={style.h1Title + " text-end font-bold" }>
                    <span class="font-extrabold">مستقبل AEV</span> <br></br>
                    <span class="font-extrabold">مدرسة السياقة</span>
                  </h1>
                  <p class={style.paragraph + " mx-auto text-end " }>لا تضيّع المزيد من الوقت, فنحن نضع تحت تصرفك أفضل
                    الأدوات <br />و أحسن التقنيات المتواجدة في مجال المدرسة السياقة </p>
                  <div class="relative flex flex-col sm:flex-row sm:space-x-4 justify-end">
                  <button class={style.buyBtn} type="button" onClick={() => setShowModal(true)}>
                                  <div class={"grid grid-cols-2 items-center"}>
                                    <div class="justify-self-center">
                                    <img src={icondown} alt='icon down' class={style.icondown} />
                                      </div>
                                    <div>
                                      <span class={style.iconDown1Span}>AEV Simulator</span>
                                      <br></br>
                                      <span class={style.iconDown2Span}>Preorder</span>
                                    </div>
                                  </div> 
                        </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <section class={style.sectionMobile} style={{display:"none"}}>
        <div>
          <div>
            <div class='grid items-center justify-center justify-items-center	 m-4'>
              <h1 class={style.H1Section1Mobile}>مستقبل AEV مدرسة السياقة</h1>
              <p class={style.pSection1Mobile}>لا تضيّع المزيد من الوقت, فنحن نضع تحت تصرفك أفضل الأدوات و أحسن التقنيات
                المتواجدة في مجال المدرسة السياقة</p>
            </div>
            <div class={style.paddingTitle+" flex items-center justify-center justify-items-center m-4"}>
              <div class={style.titleHeader}>قم بتشغيل الفيديو لاستكشاف المستقبل</div>
              <a href='https://www.youtube.com/' class={style.Ellipse}>
                <div class={style.Ellipse6}>
                  <FaPlay fill='#1f0d3c' />
                </div>
              </a>
            </div>
          </div>
          <div class={"flex justify-center"}>
            <img class={style.imageMobile} src={laptopMobileImg} alt='phone 1' />
          </div>
        </div>
        <div class="flex justify-center">
        <button class={style.downloadBtnPlayStoreMobile} onClick={() => setShowModal(true)}>
                <div class={"flex justify-center"}>
                    <div><img src={icondown} alt='icon down' class={style.icondown} /></div>
                    <div>
                      <span class={style.iconDown1Span}>AEV Simulator</span>
                      <br></br>
                      <span class={style.iconDown2Span}>Preorder</span>
                      </div>
                </div>
              </button>

        </div>

      </section>
      {showModal ? (
            <>
                   <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="fixed inset-0 w-full h-full modalSection" onClick={() => setShowModal(false)}></div>
                        <div className="flex items-center min-h-screen px-4 py-8 justify-center	">
                            <div class={style.formDovModal}>
                                <div className={style.h1Form}>
                                  <span> طلب مسبق للمحاكي </span>
                                </div>

                                <form method='get'>
                                  <input type="text" class={style.fullnameInp} placeholder='الاسم الكامل' />
                                  <input type="tel" class={style.phoneNumberInp} placeholder='رقم الهاتف' />
                                  <input type="email" class={style.phoneNumberInp} placeholder='البريد الإلكتروني' />
                                  <select class={style.SeelctOption}>
                                    <option value="">الموضوع</option>
                                    <option>1</option>
                                    <option>2</option>
                                  </select>
                                  <button type='submit' class={style.subBtn}><span class={style.subBtnSpan}>أطلب</span></button>
                                </form>
                            </div>
                        </div>
                    </div>

            </>
          ) : null}
          
    </>
  );
}
export default Section1;