import style from './Style/Section1.module.css'
import logoAev from '../../assets/images/logosolo.png'
import {useState} from 'react';

function Section1() {
  const cities = [
    {
      value:'MEKNES',
      label:'MEKNES'
    },
    {
      value:'AGADIR',
      label:'AGADIR'
    }
  ]
  const area = [
    {
      value:"ALMORABITIN AUTO-ECOLE",
      label:"ALMORABITIN AUTO-ECOLE"
    },
    {
      value:"ESSALAM AUTO-ECOLE",
      label:"ESSALAM AUTO-ECOLE"
    }
  ]
  
    const [selectedArea, setSelectedArea] = useState(area[0].value);
    const [selectedCity, setSelectedCity] = useState(cities[0].value);
    function changeCity(city) {setSelectedCity(city)}
    function changeArea(area) {setSelectedArea(area)}
    return (
      <>
        <section class={style.sectionLaptop}>
          <div class={style.divGlobal}>
            <div class={style.mapSection}>
              <div class={"grid grid-cols-3 ml-2 "+ style.changeDisplay}>
                <div class={"flex "+ style.flexDisplay}>
                  <div class={style.buttonCity}>
                    <svg class={style.svgCity} viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'><rect width='24' height='24' stroke='none' fill='#1f0d3c' opacity='0'/><g transform="matrix(0.47 0 0 0.47 12 12)" ><path  transform=" translate(-25, -24.81)" d="M 12 3.6132812 L 12 28 L 9 28 L 9 23 L 7 23 L 7 28 L 4 28 L 4 29 L 4 46 L 21 46 L 35 46 L 35 11.279297 L 32 10.279297 L 32 4 L 30 4 L 30 9.6113281 L 28 8.9453125 L 28 4 L 26 4 L 26 8.2792969 L 12 3.6132812 z M 14 6.3886719 L 33 12.720703 L 33 44 L 28 44 L 28 40.855469 C 29.715786 40.405591 31 38.850301 31 37 C 31 34.802706 29.197294 33 27 33 C 24.802706 33 23 34.802706 23 37 C 23 38.850301 24.284214 40.405591 26 40.855469 L 26 44 L 21 44 L 21 28 L 14 28 L 14 6.3886719 z M 16 12 L 16 14 L 22 14 L 22 12 L 16 12 z M 37 15 L 37 17 L 44 17 L 44 44 L 37 44 L 37 46 L 46 46 L 46 15 L 37 15 z M 16 16 L 16 18 L 31 18 L 31 16 L 16 16 z M 37 19 L 37 22 L 42 22 L 42 19 L 37 19 z M 16 20 L 16 22 L 24 22 L 24 20 L 16 20 z M 26 20 L 26 22 L 31 22 L 31 20 L 26 20 z M 16 24 L 16 26 L 31 26 L 31 24 L 16 24 z M 37 24 L 37 27 L 42 27 L 42 24 L 37 24 z M 23 28 L 23 30 L 31 30 L 31 28 L 23 28 z M 37 29 L 37 32 L 42 32 L 42 29 L 37 29 z M 6 30 L 19 30 L 19 44 L 6 44 L 6 30 z M 8 32 L 8 34 L 11 34 L 11 32 L 8 32 z M 14 32 L 14 34 L 17 34 L 17 32 L 14 32 z M 37 34 L 37 37 L 42 37 L 42 34 L 37 34 z M 27 35 C 28.116414 35 29 35.883586 29 37 C 29 38.116414 28.116414 39 27 39 C 25.883586 39 25 38.116414 25 37 C 25 35.883586 25.883586 35 27 35 z M 8 36 L 8 38 L 11 38 L 11 36 L 8 36 z M 14 36 L 14 38 L 17 38 L 17 36 L 14 36 z M 37 39 L 37 42 L 42 42 L 42 39 L 37 39 z M 8 40 L 8 42 L 11 42 L 11 40 L 8 40 z M 14 40 L 14 42 L 17 42 L 17 40 L 14 40 z" stroke-linecap="round" /></g></svg>
                    <span class={style.spanCity}>CITY</span>
                  </div>
                  <select class={style.buttonSelectCity} value={selectedCity} onChange={e => changeCity(e.target.value)}>
                    {cities.map(o => (
                      <option key={o.value} value={o.value}>{o.label}</option>
                    ))}
                  </select>
                </div>
                <div class={"flex "+ style.flexDisplay}>
                  <div class={style.buttonCity + " " + style.buttonCityArea} >
                    <img class={style.logoAevSvg} src={logoAev} alt='logoAev' />
                    <span class={style.spanArea}>AEV AREA</span>
                  </div>
                  <select class={style.buttonSelectCity} value={selectedArea} onChange={e => changeArea(e.target.value)}>
                    {area.map(o => (
                      <option key={o.value} value={o.value}>{o.label}</option>
                    ))}
                  </select>
                </div>

              </div>
              
              <div class={style.secondDiv}>
                {selectedCity === "MEKNES" && <iframe  class={style.mapGoogle} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d52997.3767853997!2d-5.579219737556924!3d33.88099814456943!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xda044d23bfc49d1%3A0xfbbf80a99e4cde18!2zTWVrbsOocw!5e0!3m2!1sfr!2sma!4v1682419935874!5m2!1sfr!2sma" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title='map'></iframe>}
                {selectedCity === "AGADIR" && <iframe  class={style.mapGoogle} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d55049.23231784397!2d-9.618944169375721!3d30.419740532366713!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xdb3b6e9daad1cc9%3A0xbcf8d0b78bf48474!2sAgadir%2080000!5e0!3m2!1sfr!2sma!4v1682421030660!5m2!1sfr!2sma" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title='map2'></iframe>}
              </div>
            </div>
          </div>
          <div>
            <ul className={style.ulNavDropdown}>
              <li className={style.selected}><a href="/#app" class={"inline-block no-underline"}>AEV App</a></li>
              <li className={style.selected}><a href="/#platform" class={"inline-block no-underline"}>AEV Platform</a></li>
              <li className={style.selected}><a href="/#simulator" class={"inline-block no-underline"}>AEV Simulator</a></li>
            </ul>
          </div>
        </section>
        <section class={style.sectionMobile} style={{display:"none"}}>
          

        </section>
      </>

    );
}
  
export default Section1;