import { useState, useEffect, useRef  } from 'react';

import style from './Style/Section1.module.css';
import { FaPlay } from 'react-icons/fa';
/* -------------------------------------------------------------- */
import laptop2 from '../../../assets/images/laptop.png';
import laptop from '../../../assets/images/laptop2.png';
import laptopMobileImg from '../../../assets/images/laptopMobileImg.png';
import axios from 'axios';


function Section1() {
  
  const API_URI = 'https://api.github.com/repos/0xm3t1/aev-releases/releases/latest';
  const [profile, setProfile] = useState([])
  const getProfiles = async () => {
    try {
      const res = await axios.get(API_URI);
      const url = res.data.assets.find(asset => /.*\.exe$/.test(asset.name)).browser_download_url;
      console.log(url);
      setProfile(url);
    } catch (error) {
      console.log(error);
    }
  }

  const section1 = useRef(null);
  const [isVisibleSection1, setIsVisibleSection1] = useState(false);
  useEffect(() => {

    window.addEventListener('load', getProfiles);

    const observer1 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisibleSection1(true);
          observer1.unobserve(entry.target);
        }
      });
    });

    const correntSection = section1.current;

    if (correntSection) {
      observer1.observe(correntSection);
    }

    return () => {
      if (correntSection) {
        observer1.unobserve(correntSection);
      }
      window.removeEventListener('load', getProfiles)
    };
  }, [profile]);

  return (
    <>
      {/* ---------------------------------SECTION 1 ------------------------------------- */}
      <section ref={section1} class={`${isVisibleSection1 ? style.sectionLaptop : style.section2LaptopVeisuble}`}>
        <div class={style.gridSection1 + " grid grid-cols-2" }>
          <div>
            <div class="w-full">
              <div class={style.images}>
                <div class={style.parent}>
                  <img class={style.image2} alt="img2" src={laptop2} />
                  <img class={style.image1} alt="imafe1" src={laptop} />
                </div>
              </div>
            </div>
          </div>
          <div class="flex items-center">
            <div class="w-full">
              <div class={style.divH}>
                <div class={style.paddingTitle+" mt-12 flex items-center text-end place-content-end"}>
                  <div class={style.titleHeader}>قم بتشغيل الفيديو لاستكشاف المستقبل</div>
                  <a href='https://www.youtube.com/' class={style.Ellipse}>
                    <div class={style.Ellipse6}>
                      <FaPlay fill='#fff' />
                    </div>
                  </a>
                </div>
                <div class={style.paddingTitle
                  + " w-full md:space-y-4 lg:space-y-8 xl:space-y-9 sm:pr-6 lg:pr-0 md:pb-0 pr-5" }>
                  <h1 class={style.h1Title + " text-end font-bold" }>
                    <span class="font-extrabold">مستقبل AEV</span> <br></br>
                    <span class="font-extrabold">مدرسة السياقة</span>
                  </h1>
                  <p class={style.paragraph + " mx-auto text-end " }>لا تضيّع المزيد من الوقت, فنحن نضع تحت تصرفك أفضل
                    الأدوات <br />و أحسن التقنيات المتواجدة في مجال المدرسة السياقة </p>
                  <div class="relative flex flex-col sm:flex-row sm:space-x-4 justify-end">
                    <a href="/" class={style.buyBtn} type="button">
                      <span class={style.platform}>AEV Platform V1.0</span>
                      <div class={"grid grid-cols-2 items-center"}>
                        <div class="justify-self-center">
                          <svg class={style.icondown} width="512" height="512" xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 496.255 608.728">
                            <path
                              d="M273.81 52.973C313.806.257 369.41 0 369.41 0s8.271 49.562-31.463 97.306c-42.426 50.98-90.649 42.638-90.649 42.638s-9.055-40.094 26.512-86.971zM252.385 174.662c20.576 0 58.764-28.284 108.471-28.284 85.562 0 119.222 60.883 119.222 60.883s-65.833 33.659-65.833 115.331c0 92.133 82.01 123.885 82.01 123.885s-57.328 161.357-134.762 161.357c-35.565 0-63.215-23.967-100.688-23.967-38.188 0-76.084 24.861-100.766 24.861C89.33 608.73 0 455.666 0 332.628c0-121.052 75.612-184.554 146.533-184.554 46.105 0 81.883 26.588 105.852 26.588z"
                              fill="#1f0d3c" /></svg>
                        </div>
                        <div class={style.textBuybtn}>
                          <span class={style.iconDown1Span}>Download</span>
                          <br></br>
                          <span class={style.iconDown2Span}>Mac <span class="font-bold">M1</span></span>
                        </div>
                      </div>

                    </a>

                    <a href={profile} class={style.buyBtn} type="button">
                      <span class={style.platform}>AEV Platform V1.0</span>
                      <div class={"grid grid-cols-2 items-center"}>
                        <div class="justify-self-center">
                          <svg class={style.icondown} width="512" height="512" fill="#1f0d3c" viewBox="0 0 1920 1920"
                            xmlns="http://www.w3.org/2000/svg">
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                            <g id="SVGRepo_iconCarrier">
                              <path
                                d="M1863.53 1016.437c31.171 0 56.47 25.299 56.47 56.47v790.589c0 16.376-7.115 31.849-19.313 42.465-10.39 9.149-23.605 14.005-37.158 14.005-2.484 0-5.082-.113-7.567-.452l-903.53-123.331c-28.008-3.84-48.903-27.784-48.903-56.02v-667.256c0-31.171 25.3-56.47 56.471-56.47Zm-1129.412 0c31.171 0 56.47 25.299 56.47 56.47v634.504c0 16.376-7.115 31.85-19.426 42.579-10.39 9.035-23.491 13.891-37.044 13.891-2.485 0-5.196-.113-7.68-.564L48.79 1669.35C20.78 1665.51 0 1641.68 0 1613.444v-540.537c0-31.171 25.299-56.47 56.47-56.47Zm-7.726-859.855c16.151-2.372 32.415 2.597 44.725 13.327 12.424 10.73 19.426 26.315 19.426 42.579V846.99c0 31.285-25.186 56.47-56.47 56.47H56.424c-31.171 0-56.47-25.185-56.47-56.47V306.455c0-28.123 20.781-52.066 48.79-55.906ZM1855.974.474c16.15-2.033 32.414 2.71 44.724 13.44 12.198 10.73 19.313 26.203 19.313 42.466v790.588c0 31.285-25.299 56.471-56.47 56.471H960.01c-31.171 0-56.47-25.186-56.47-56.47V179.711c0-28.235 20.78-52.066 48.903-55.906Z"
                                fill-rule="evenodd"></path>
                            </g>
                          </svg>

                          {/* <svg class={style.icondown} width="512" height="512" xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 496.255 608.728">
                            <path
                              d="M273.81 52.973C313.806.257 369.41 0 369.41 0s8.271 49.562-31.463 97.306c-42.426 50.98-90.649 42.638-90.649 42.638s-9.055-40.094 26.512-86.971zM252.385 174.662c20.576 0 58.764-28.284 108.471-28.284 85.562 0 119.222 60.883 119.222 60.883s-65.833 33.659-65.833 115.331c0 92.133 82.01 123.885 82.01 123.885s-57.328 161.357-134.762 161.357c-35.565 0-63.215-23.967-100.688-23.967-38.188 0-76.084 24.861-100.766 24.861C89.33 608.73 0 455.666 0 332.628c0-121.052 75.612-184.554 146.533-184.554 46.105 0 81.883 26.588 105.852 26.588z"
                              fill="#1f0d3c" /></svg> */}
                        </div>
                        <div class={style.textBuybtn}>
                          <span class={style.iconDown1Span}>Download <span class="font-extrabold">32/64<sup
                                class="font-extrabold">Bit</sup></span></span>
                          <br></br>
                          <span class={style.iconDown2Span}>Windows</span>
                        </div>
                      </div>

                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <section class={style.sectionMobile} style={{display:"none"}}>
        <div>
          <div>
            <div class='grid items-center justify-center justify-items-center	 m-4'>
              <h1 class={style.H1Section1Mobile}>مستقبل AEV <br />مدرسة السياقة</h1>
              <p class={style.pSection1Mobile}>لا تضيّع المزيد من الوقت, فنحن نضع تحت تصرفك أفضل الأدوات و أحسن التقنيات
                المتواجدة في مجال المدرسة السياقة</p>
            </div>
            <div class={style.paddingTitle+" flex items-center justify-center justify-items-center m-4"}>
              <div class={style.titleHeader}>قم بتشغيل الفيديو لاستكشاف المستقبل</div>
              <a href='https://www.youtube.com/' class={style.Ellipse}>
                <div class={style.Ellipse6}>
                  <FaPlay fill='#fff' />
                </div>
              </a>
            </div>
          </div>
          <div class={"flex justify-center"}>
            <img class={style.imageMobile} src={laptopMobileImg} alt='phone 1' />
          </div>
        </div>
        <div class="flex justify-center">
          <a href="/" class={style.downloadBtnPlayStoreMobile} type="button">
            <span class={style.platform}>AEV Platform V1.0</span>
            <div class={"grid grid-cols-2 items-center"}>
              <div class="justify-self-center">
                <svg class={style.icondownMobile} width="512" height="512" xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 496.255 608.728">
                  <path
                    d="M273.81 52.973C313.806.257 369.41 0 369.41 0s8.271 49.562-31.463 97.306c-42.426 50.98-90.649 42.638-90.649 42.638s-9.055-40.094 26.512-86.971zM252.385 174.662c20.576 0 58.764-28.284 108.471-28.284 85.562 0 119.222 60.883 119.222 60.883s-65.833 33.659-65.833 115.331c0 92.133 82.01 123.885 82.01 123.885s-57.328 161.357-134.762 161.357c-35.565 0-63.215-23.967-100.688-23.967-38.188 0-76.084 24.861-100.766 24.861C89.33 608.73 0 455.666 0 332.628c0-121.052 75.612-184.554 146.533-184.554 46.105 0 81.883 26.588 105.852 26.588z"
                    fill="#1f0d3c" /></svg>
              </div>
              <div class={style.textBuybtn}>
                <span class={style.iconDown1SpanMobile}>Download</span>
                <br></br>
                <span class={style.iconDown2SpanMobile}>Mac <span class="font-bold">M1</span></span>
              </div>
            </div>

          </a>

          <a href="/" class={style.downloadBtnPlayStoreMobile} type="button">
            <span class={style.platform}>AEV Platform V1.0</span>
            <div class={"grid grid-cols-2 items-center"}>
              <div class="justify-self-center">
                <svg class={style.icondownMobile} width="512" height="512" fill="#1f0d3c" viewBox="0 0 1920 1920"
                  xmlns="http://www.w3.org/2000/svg">
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                  <g id="SVGRepo_iconCarrier">
                    <path
                      d="M1863.53 1016.437c31.171 0 56.47 25.299 56.47 56.47v790.589c0 16.376-7.115 31.849-19.313 42.465-10.39 9.149-23.605 14.005-37.158 14.005-2.484 0-5.082-.113-7.567-.452l-903.53-123.331c-28.008-3.84-48.903-27.784-48.903-56.02v-667.256c0-31.171 25.3-56.47 56.471-56.47Zm-1129.412 0c31.171 0 56.47 25.299 56.47 56.47v634.504c0 16.376-7.115 31.85-19.426 42.579-10.39 9.035-23.491 13.891-37.044 13.891-2.485 0-5.196-.113-7.68-.564L48.79 1669.35C20.78 1665.51 0 1641.68 0 1613.444v-540.537c0-31.171 25.299-56.47 56.47-56.47Zm-7.726-859.855c16.151-2.372 32.415 2.597 44.725 13.327 12.424 10.73 19.426 26.315 19.426 42.579V846.99c0 31.285-25.186 56.47-56.47 56.47H56.424c-31.171 0-56.47-25.185-56.47-56.47V306.455c0-28.123 20.781-52.066 48.79-55.906ZM1855.974.474c16.15-2.033 32.414 2.71 44.724 13.44 12.198 10.73 19.313 26.203 19.313 42.466v790.588c0 31.285-25.299 56.471-56.47 56.471H960.01c-31.171 0-56.47-25.186-56.47-56.47V179.711c0-28.235 20.78-52.066 48.903-55.906Z"
                      fill-rule="evenodd"></path>
                  </g>
                </svg>
              </div>
              <div class={style.textBuybtn}>
                <span class={style.iconDown1SpanMobile}>Download <span class="font-extrabold">32<sup
                      class="font-extrabold">Bit</sup></span></span>
                <br></br>
                <span class={style.iconDown2SpanMobile}>Windows</span>
              </div>
            </div>

          </a>

          <a href="/" class={style.downloadBtnPlayStoreMobile} type="button">
            <span class={style.platform}>AEV Platform V1.0</span>
            <div class={"grid grid-cols-2 items-center"}>
              <div class="justify-self-center">
                <svg class={style.icondownMobile} width="512" height="512" fill="#1f0d3c" viewBox="0 0 1920 1920"
                  xmlns="http://www.w3.org/2000/svg">
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                  <g id="SVGRepo_iconCarrier">
                    <path
                      d="M1863.53 1016.437c31.171 0 56.47 25.299 56.47 56.47v790.589c0 16.376-7.115 31.849-19.313 42.465-10.39 9.149-23.605 14.005-37.158 14.005-2.484 0-5.082-.113-7.567-.452l-903.53-123.331c-28.008-3.84-48.903-27.784-48.903-56.02v-667.256c0-31.171 25.3-56.47 56.471-56.47Zm-1129.412 0c31.171 0 56.47 25.299 56.47 56.47v634.504c0 16.376-7.115 31.85-19.426 42.579-10.39 9.035-23.491 13.891-37.044 13.891-2.485 0-5.196-.113-7.68-.564L48.79 1669.35C20.78 1665.51 0 1641.68 0 1613.444v-540.537c0-31.171 25.299-56.47 56.47-56.47Zm-7.726-859.855c16.151-2.372 32.415 2.597 44.725 13.327 12.424 10.73 19.426 26.315 19.426 42.579V846.99c0 31.285-25.186 56.47-56.47 56.47H56.424c-31.171 0-56.47-25.185-56.47-56.47V306.455c0-28.123 20.781-52.066 48.79-55.906ZM1855.974.474c16.15-2.033 32.414 2.71 44.724 13.44 12.198 10.73 19.313 26.203 19.313 42.466v790.588c0 31.285-25.299 56.471-56.47 56.471H960.01c-31.171 0-56.47-25.186-56.47-56.47V179.711c0-28.235 20.78-52.066 48.903-55.906Z"
                      fill-rule="evenodd"></path>
                  </g>
                </svg>
              </div>
              <div class={style.textBuybtn}>
                <span class={style.iconDown1SpanMobile}>Download <span class="font-extrabold">64<sup
                      class="font-extrabold">Bit</sup></span></span>
                <br></br>
                <span class={style.iconDown2SpanMobile}>Windows</span>
              </div>
            </div>

          </a>


        </div>
      </section>
    </>
  );
}
export default Section1;