
import { useState, useEffect, useRef  } from 'react';
import React from 'react';
import { Link } from "react-router-dom";

import style from './Style/Section2.module.css'

export default function Section1_2() {
  const [state, setState] = React.useState('');
  const section1 = useRef(null);
  const [isVisibleSection1, setIsVisibleSection1] = useState(false);
  useEffect(() => {
    const observer1 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisibleSection1(true);
          observer1.unobserve(entry.target);
        }
      });
    });


    const section1Current = section1.current;

    if (section1Current) {
      observer1.observe(section1Current);
    }


    return () => {
      if (section1Current) {
        observer1.unobserve(section1Current);
      }
      
    };
  }, []);

    return (
      <>
        <section ref={section1} class={`${isVisibleSection1 ? style.section2ABoutDesktop : style.section2LaptopVeisuble}`}>
          <div class={style.about}>
            <h1 class={style.aboutH1_1}><span>AEV</span> كن جزءًا من</h1>
            <h1 class={style.aboutH1_2}>,تعمل على تغيير حياتك <span> AEV</span> <br></br> دع عملك ينمو</h1>
            <div class={style.divAbout}>
                <div class={style.divAboutDiv}>
                <div class={"grid grid-cols-2"}>
                <div>
                <Link type="button" class={style.abtBtn}
                  to="/contact" state={{ email: state }}>
                    <span>اتصل بنا</span></Link>
                </div>
                <div><input value={state}
        onChange={(event) => {
          setState(event.target.value);
        }} type="text" placeholder="👋 أدخل عنوان البريد الإلكتروني الخاص بك مرحبا" class={style.h1_btn} /></div>
                </div>
                </div>
            </div>
          </div>
        </section>
        <section class={style.section2ABoutMobile} style={{display:"none"}}>
          <h1 class={style.aboutH1_1Mobile}><span>AEV</span> كن جزءًا من</h1>
          <h1 class={style.aboutH1_2Mobile}>,تعمل على تغيير حياتك <span> AEV</span> <br></br> دع عملك ينمو</h1>

          <div class={style.divAboutMobile}>
                <div class={style.divAboutDivMobile}>
                <div class={"grid grid-cols-2"}>
                <div>
                      <Link type="button" class={style.abtBtnMobile}
                            to="/contact" state={{ email: state }}>
                              <span>اتصل بنا</span>
                      </Link>
                    </div>
                    <div style={{alignSelf: "center"}}>
                      <input value={state}
                          onChange={(event) => {
                            setState(event.target.value);
                          }} type="text" placeholder="👋 أدخل عنوان البريد الإلكتروني الخاص بك مرحبا" class={style.h1_btnMobile} />
                    </div>
                </div>
                </div>
            </div>
        </section>
      
      </>
    );
}
  
