import Header from '../partials/header';
import Section1 from '../components/About/Section1';
import AboutSection2 from '../partials/footer/section2';
import Footer from '../partials/footer/Footer';

import style from '../components/Home.module.css'

function App() {
  return (
    <div class="h-screen">
       
      <div className="App overflow-x-auto overflow-scroll overflow-hidden" class="section1" >
        <div class={style.divGlobalHome } style={{backgroundColor:'#f9f6ff94 !important'}}>
          <Header />
          <Section1 />
          <AboutSection2 />
          <Footer />
        </div>
      </div>
      <div class="snap-start"></div>
    </div>
  );
}

export default App;
