

import style from "./Style/Section1.module.css";

import phone1 from '../../assets/images/phone1.png'
import phone2 from '../../assets/images/phone2.png'
import phone3 from '../../assets/images/phone4.png';

import path456 from '../../assets/images/path456.png';

import path from '../../assets/images/path.png';
import play from '../../assets/images/play.png';
import card from '../../assets/images/card1.png';

import cardPage1 from '../../assets/images/cardPage1.png';
import cardPage2 from '../../assets/images/cardPage2.png';
import cardPage3 from '../../assets/images/cardPage3.png';

import { Link } from "react-router-dom";



import logo1 from '../../assets/images/Group 1418.svg';
import logo2 from '../../assets/images/Group 1417.svg';
import logo3 from '../../assets/images/Group 1416.svg';

import appStore from '../../assets/images/appstore.png';
import playStore from '../../assets/images/playStore.png';
import {useState} from 'react';
import { FaPlay } from 'react-icons/fa';

function Section1() {
  const [index, setIndex] = useState(0);
  const [index2, setIndex2] = useState(1);

  const [indexMobile, setIndexMobile] = useState(0);
  const [index2Mobile, setIndex2Mobile] = useState(0);

 console.log("fff");

    return (
      <>
      {/* ----------------------------------SECTION1---------------------------------- */}
        <div className={style.section1Desktop}>
          <section class={style.divH1}>
            <h1 className={style.headerH1}>التطبيق التعليمي للمدرسة السياقة فقط في جيبك</h1>
            <div class={"flex items-center justify-center " + style.playVid} >
              <p class={style.playText}>قم بتشغيل الفيديو لاستكشاف المستقبل</p>
              {/* <svg class={style.svgplay} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M11.9707 22C17.4936 22 21.9707 17.5228 21.9707 12C21.9707 6.47715 17.4936 2 11.9707 2C6.44786 2 1.9707 6.47715 1.9707 12C1.9707 17.5228 6.44786 22 11.9707 22Z" stroke="#1f0d3c" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path opacity="0.4" d="M8.74023 12.2301V10.5601C8.74023 8.48012 10.2102 7.63012 12.0102 8.67012L13.4602 9.51012L14.9102 10.3501C16.7102 11.3901 16.7102 13.0901 14.9102 14.1301L13.4602 14.9701L12.0102 15.8101C10.2102 16.8501 8.74023 16.0001 8.74023 13.9201V12.2301Z" stroke="#1f0d3c" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg> */}
              <a href='https://www.youtube.com/' class={style.Ellipse}>
                        <div class={style.Ellipse6}>
                          <FaPlay fill='#1f0d3c'/>
                        </div>
                      </a>
            </div>
            <div class={style.phonesDiv + " flex justify-center"}>
              <div class="justify-self-center"><img src={phone2} class={style.phone1 + " " + style.phoneImg2} alt="phone1" /></div>
              <div class="justify-self-center"><img src={phone1} class={style.phone1 + " " + style.phoneImg1} alt="phone1"/></div>
              <div class="justify-self-center"><img src={phone3} class={style.phone1 + " " + style.phoneImg3} alt="phone1"/></div>
            </div>
          </section>
          <section class={style.aboutDef} >
            <div class={"grid grid-cols-3"}>
              <div className={style.pathImg}>
                <img src={path} alt='about' class={style.aboutPath} />
              </div>
              <div class={"col-span-2"}>
                <div class={style.aboutRec}>
                  <div class="grid grid-cols-3 gap-4">
                    <div class="col-span-2">
                      <p class={style.paragraphAbtPage}>التطبيق الهاتفي التعلمي للمرشحين، هو الجزء الخاص بشق التكوين النظري في تعليم السياقة بمنظومة AEV المتكاملة حيث يلعب دور الوسيط للربط بشكل رقمي بين المرشح و مؤسسة تعليم السياقة من أجل تمكين المدارس من تقديم تدريب عالي الجودة يدعم التعلم المخصص والتعلم عن بعد حيث يمكن لكل طالب بدء التعلم تتبع تقدمه من طرف المدرب وتحليل المهارات لكشف نِقَاط الضعف حسب التصنيفات أو الإطلاع على جدولة الحصص باستخدام تطبيق الهاتف فقط.</p>
                    </div>
                    <div>
                      <div class={style.aboutPH1}>
                        <p>تطبيق هاتفي AEV</p>
                        <h1> 
                          <span>مستقبل AEV</span>
                          <br></br>
                          <span> مدرسة السياقة</span>         
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className={style.section1Mobile} style={{display:"none"}}>
          <div class={style.h1TitleDiv}>
            <h1>التطبيق التعليمي<br /> للمدرسة السياقة <br />فقط في جيبك</h1>
          </div>
          <div class={"flex justify-center items-center"}>
                <div class={style.titleFlex}>قم بتشغيل الفيديو لاستكشاف المستقبل</div>
                <img src={play} class={style.iconStartMobile} alt="logo"/>
          </div>
          <div class={style.phonesDivMobile + " grid grid-cols-3"}>
            <div><img src={phone1} class={style.phone1Mobile} alt="phone1"/></div>
            <div><img src={phone2} class={style.phone1Mobile} alt="phone1" /></div>
            <div><img src={phone3} class={style.phone1Mobile} alt="phone1"/></div>
          </div>

          <div class={style.titleAfterImg}>
            <span>تطبيق هاتفي AEV</span>
            <h1>  مستقبل AEV <br /> 
                مدرسةالسياقة 
                </h1>
            <p class={style.paragraphSec1Mobile}>التطبيق الهاتفي التعلمي للمرشحين، هو الجزء الخاص بشق التكوين النظري في تعليم السياقة بمنظومة AEV المتكاملة</p>

            <div class={style.centerAlign}>
              <button onClick={() => setIndexMobile(0)}  class={indexMobile === 0 ? style.rec1MobileActive : style.rec1Mobile}>
                <div >
                  <div className='grid grid-cols-2'>
                    <div className={style.gridRec1Span}><span>سلاسل و امتحانات
عالية الجودة</span></div>
                    <div className={style.gridRec1Img}><img src={card} alt="card 1" /></div>
                  </div>
                </div>
              </button>
              
            </div>
            <p class={indexMobile === 0 ? style.paragraphSec1Mobile2 : "hidden"}>التطبيق الهاتفي التعلمي للمرشحين، هو الجزء الخاص بشق التكوين النظري في تعليم السياقة بمنظومة AEV المتكاملة حيث يلعب دور الوسيط للربط بشكل رقمي بين المرشح و مؤسسة تعليم السياقة من أجل تمكين المدارس من تقديم تدريب عالي الجودة يدعم التعلم المخصص والتعلم عن بعد حيث يمكن لكل طالب بدء التعلم تتبع تقدمه من طرف المدرب وتحليل المهارات لكشف نِقَاط الضعف حسب التصنيفات أو الإطلاع على جدولة الحصص باستخدام تطبيق الهاتف فقط.</p>

          </div>





        </div>
      {/* ----------------------------------SECTION1---------------------------------- */}
        <section className={style.section2Dectop}>
          <div class={style.sectionAbout + " grid grid-cols-2"}>
            {index === 0 &&
              <div class={style.sectionAb}>
                  <h1 class={style.h1section}> سلاسل و امتحانات عالية الجودة</h1>
                  <p class={style.paragraphSection}>التطبيق الهاتفي التعلمي للمرشحين، هو الجزء الخاص بشق التكوين النظري في تعليم السياقة بمنظومة AEV 
                      المتكاملة حيث يلعب دور الوسيط للربط بشكل رقمي بين 
                      المرشح و مؤسسة تعليم السياقة من أجل تمكين المدارس 
                      من تقديم تدريب عالي الجودة يدعم التعلم المخصص والتعلم 
                      عن بعد حيث يمكن لكل طالب بدء التعلم تتبع تقدمه من 
                      طرف المدرب وتحليل المهارات لكشف نِقَاط الضعف حسب 
                      التصنيفات  أو أو الإطلاع على جدولة الحصص باستخدام 
                      تطبيق الهاتف فقط
                  </p>
              </div>
            }
            {index === 1 &&
              <div class={style.sectionAb}>
                  <h1 class={style.h1section}>دروس مركزة و مختصرة</h1>
                  <p class={style.paragraphSection}>التطبيق الهاتفي التعلمي للمرشحين، هو الجزء الخاص بشق التكوين النظري في تعليم السياقة بمنظومة AEV 
                      المتكاملة حيث يلعب دور الوسيط للربط بشكل رقمي بين 
                      المرشح و مؤسسة تعليم السياقة من أجل تمكين المدارس 
                      من تقديم تدريب عالي الجودة يدعم التعلم المخصص والتعلم 
                      عن بعد حيث يمكن لكل طالب بدء التعلم تتبع تقدمه من 
                      طرف المدرب وتحليل المهارات لكشف نِقَاط الضعف حسب 
                      التصنيفات  أو أو الإطلاع على جدولة الحصص باستخدام 
                      تطبيق الهاتف فقط
                  </p>
              </div>
            }
            {index === 2 &&
              <div class={style.sectionAb}>
                  <h1 class={style.h1section}>محتوى مراقب
                                                ومحدث بإستمرار</h1>
                  <p class={style.paragraphSection}>التطبيق الهاتفي التعلمي للمرشحين، هو الجزء الخاص بشق التكوين النظري في تعليم السياقة بمنظومة AEV 
                      المتكاملة حيث يلعب دور الوسيط للربط بشكل رقمي بين 
                      المرشح و مؤسسة تعليم السياقة من أجل تمكين المدارس 
                      من تقديم تدريب عالي الجودة يدعم التعلم المخصص والتعلم 
                      عن بعد حيث يمكن لكل طالب بدء التعلم تتبع تقدمه من 
                      طرف المدرب وتحليل المهارات لكشف نِقَاط الضعف حسب 
                      التصنيفات  أو أو الإطلاع على جدولة الحصص باستخدام 
                      تطبيق الهاتف فقط
                  </p>
              </div>
            }
            
            <div class="text-center">
              <button onClick={() => setIndex(0)}  class={index === 0 ? style.aboutSection31Active : style.aboutSection31}>
                <div class={"flex items-center justify-center"}>
                    <span> سلاسل و امتحانات عالية الجودة </span>
                    <img src={cardPage1} alt="card" class={style.imgCard} />
                </div>
              </button>
              <button onClick={() => setIndex(1)}  class={index === 1 ? style.aboutSection31Active : style.aboutSection31}>
                <div class={"flex items-center justify-center"}>
                    <span>دروس مركزة و مختصرة</span>
                    <img src={cardPage2} alt="card" class={style.imgCard} />
                </div>
              </button>
              <button onClick={() => setIndex(2)}  class={index === 2 ? style.aboutSection31Active : style.aboutSection31}>
                <div class={"flex items-center justify-center"}>
                    <span>محتوى مراقب
                                                ومحدث بإستمرار</span>
                    <img src={cardPage3} alt="card" class={style.imgCard} />
                </div>
              </button>
            </div>
          </div>
        </section>
        <section className={style.section2Mobile} style={{display:"none"}}>
          <div class={style.centerAlign}>
            <button onClick={() => setIndexMobile(1)}  class={indexMobile === 1 ? style.rec1MobileActive : style.rec1Mobile}>
              <div>
                <div className='grid grid-cols-2'>
                  <div className={style.gridRec1Span}><span>دروس مركزة و مختصرة</span></div>
                  <div className={style.gridRec1Img}><img src={cardPage1} alt="card 1" /></div>
                </div>
              </div>
            </button>  
          </div>
          <p class={indexMobile === 1 ? style.paragraphSec1Mobile2 : "hidden"}>التطبيق الهاتفي التعلمي للمرشحين، هو الجزء الخاص بشق التكوين النظري في تعليم السياقة بمنظومة AEV المتكاملة حيث يلعب دور الوسيط للربط بشكل رقمي بين المرشح و مؤسسة تعليم السياقة من أجل تمكين المدارس من تقديم تدريب عالي الجودة يدعم التعلم المخصص والتعلم عن بعد حيث يمكن لكل طالب بدء التعلم تتبع تقدمه من طرف المدرب وتحليل المهارات لكشف نِقَاط الضعف حسب التصنيفات أو الإطلاع على جدولة الحصص باستخدام تطبيق الهاتف فقط.</p>
          <div class={style.centerAlign}>
            <button onClick={() => setIndexMobile(2)}  class={indexMobile === 2 ? style.rec1MobileActive : style.rec1Mobile}>
              <div>
                <div className='grid grid-cols-2'>
                  <div className={style.gridRec1Span}><span>محتوى مراقب
 ومحدث بإستمرار</span></div>
                  <div className={style.gridRec1Img}><img src={card} alt="card 1" /></div>
                </div>
              </div>
            </button>  
          </div>
          <p class={indexMobile === 2 ? style.paragraphSec1Mobile2 : "hidden"}>التطبيق الهاتفي التعلمي للمرشحين، هو الجزء الخاص بشق التكوين النظري في تعليم السياقة بمنظومة AEV المتكاملة حيث يلعب دور الوسيط للربط بشكل رقمي بين المرشح و مؤسسة تعليم السياقة من أجل تمكين المدارس من تقديم تدريب عالي الجودة يدعم التعلم المخصص والتعلم عن بعد حيث يمكن لكل طالب بدء التعلم تتبع تقدمه من طرف المدرب وتحليل المهارات لكشف نِقَاط الضعف حسب التصنيفات أو الإطلاع على جدولة الحصص باستخدام تطبيق الهاتف فقط.</p>
          
          
          
          <div className={style.recMobileDiv} class="flex justify-center items-center">
            {/* <img src={recMobile} alt="recMobile" /> */}
            <div className={style.redCircle}></div>
            <div className={style.redPolygon}></div>
            <div className={style.redTriangle}></div>
            
            </div>


          <div class={style.centerAlign}>
            <button onClick={() => setIndexMobile(1)}  class={indexMobile === 1 ? style.rec1MobileActive : style.rec1Mobile}>
              <div>
                <div className='grid grid-cols-2'>
                  <div className={style.gridRec1Span}><span>دروس مركزة و مختصرة</span></div>
                  <div className={style.gridRec1Img}><img src={cardPage1} alt="card 1" /></div>
                </div>
              </div>
            </button>  
          </div>
            <div class={style.centerAlign}>
              <button onClick={() => setIndex2Mobile(0)}  class={index2Mobile === 0 ? style.rec1MobileActive : style.rec1Mobile}>
                <div >
                  <div className='grid grid-cols-2'>
                    <div className={style.gridRec1Span}><span>سلاسل و امتحانات
عالية الجودة</span></div>
                    <div className={style.gridRec1Img}><img src={cardPage2} alt="card 1" /></div>
                  </div>
                </div>
              </button>
            </div>
            
            <p class={index2Mobile === 0 ? style.paragraphSec1Mobile2 : "hidden"}>التطبيق الهاتفي التعلمي للمرشحين، هو الجزء الخاص بشق التكوين النظري في تعليم السياقة بمنظومة AEV المتكاملة حيث يلعب دور الوسيط للربط بشكل رقمي بين المرشح و مؤسسة تعليم السياقة من أجل تمكين المدارس من تقديم تدريب عالي الجودة يدعم التعلم المخصص والتعلم عن بعد حيث يمكن لكل طالب بدء التعلم تتبع تقدمه من طرف المدرب وتحليل المهارات لكشف نِقَاط الضعف حسب التصنيفات أو الإطلاع على جدولة الحصص باستخدام تطبيق الهاتف فقط.</p>
            <div class={style.centerAlign}>
              <button onClick={() => setIndex2Mobile(1)}  class={index2Mobile === 1 ? style.rec1MobileActive : style.rec1Mobile}>
                <div>
                  <div className='grid grid-cols-2'>
                    <div className={style.gridRec1Span}><span>محتوى مراقب
 ومحدث بإستمرار</span></div>
                    <div className={style.gridRec1Img}><img src={cardPage3} alt="card 1" /></div>
                  </div>
                </div>
              </button>  
            </div>
            <p class={index2Mobile === 1 ? style.paragraphSec1Mobile2 : "hidden"}>التطبيق الهاتفي التعلمي للمرشحين، هو الجزء الخاص بشق التكوين النظري في تعليم السياقة بمنظومة AEV المتكاملة حيث يلعب دور الوسيط للربط بشكل رقمي بين المرشح و مؤسسة تعليم السياقة من أجل تمكين المدارس من تقديم تدريب عالي الجودة يدعم التعلم المخصص والتعلم عن بعد حيث يمكن لكل طالب بدء التعلم تتبع تقدمه من طرف المدرب وتحليل المهارات لكشف نِقَاط الضعف حسب التصنيفات أو الإطلاع على جدولة الحصص باستخدام تطبيق الهاتف فقط.</p>
        </section>
      {/* ----------------------------------SECTION3---------------------------------- */}
        <section className={style.section2Dectop}>
          <div class={style.sectionAbout + " grid grid-cols-2"}>
            <div class="text-center">
              <button onClick={() => setIndex2(0)}  class={index2 === 0 ? style.aboutSection31Active : style.aboutSection31}>
                <div class={"flex items-center justify-center"}>
                    <span> سلاسل و امتحانات عالية الجودة </span>
                    <img src={cardPage2} alt="card" class={style.imgCard} />
                </div>
              </button>
              <button onClick={() => setIndex2(1)}  class={index2 === 1 ? style.aboutSection31Active : style.aboutSection31}>
                <div class={"flex items-center justify-center"}>
                    <span>دروس مركزة و مختصرة</span>
                    <img src={cardPage1} alt="card" class={style.imgCard} />
                </div>
              </button>
              <button onClick={() => setIndex2(2)}  class={index2 === 2 ? style.aboutSection31Active : style.aboutSection31}>
                <div class={"flex items-center justify-center"}>
                    <span>محتوى مراقب
                                                ومحدث بإستمرار</span>
                    <img src={cardPage3} alt="card" class={style.imgCard} />
                </div>
              </button>
            </div>
            <div>

            
            {index2 === 0 &&
              <div class={style.sectionAb}>
                  <h1 class={style.h1section}> سلاسل و امتحانات عالية الجودة</h1>
                  <p class={style.paragraphSection}>التطبيق الهاتفي التعلمي للمرشحين، هو الجزء الخاص بشق التكوين النظري في تعليم السياقة بمنظومة AEV 
                      المتكاملة حيث يلعب دور الوسيط للربط بشكل رقمي بين 
                      المرشح و مؤسسة تعليم السياقة من أجل تمكين المدارس 
                      من تقديم تدريب عالي الجودة يدعم التعلم المخصص والتعلم 
                      عن بعد حيث يمكن لكل طالب بدء التعلم تتبع تقدمه من 
                      طرف المدرب وتحليل المهارات لكشف نِقَاط الضعف حسب 
                      التصنيفات  أو أو الإطلاع على جدولة الحصص باستخدام 
                      تطبيق الهاتف فقط
                  </p>
              </div>
            }
            {index2 === 1 &&
              <div class={style.sectionAb}>
                  <h1 class={style.h1section}>دروس مركزة و مختصرة</h1>
                  <p class={style.paragraphSection}>التطبيق الهاتفي التعلمي للمرشحين، هو الجزء الخاص بشق التكوين النظري في تعليم السياقة بمنظومة AEV 
                      المتكاملة حيث يلعب دور الوسيط للربط بشكل رقمي بين 
                      المرشح و مؤسسة تعليم السياقة من أجل تمكين المدارس 
                      من تقديم تدريب عالي الجودة يدعم التعلم المخصص والتعلم 
                      عن بعد حيث يمكن لكل طالب بدء التعلم تتبع تقدمه من 
                      طرف المدرب وتحليل المهارات لكشف نِقَاط الضعف حسب 
                      التصنيفات  أو أو الإطلاع على جدولة الحصص باستخدام 
                      تطبيق الهاتف فقط
                  </p>
              </div>
            }
            {index2 === 2 &&
              <div class={style.h1sec + " " + style.sectionAb}>
                  <h1 class={style.h1section}>محتوى مراقب
                                                ومحدث بإستمرار</h1>
                  <p class={style.paragraphSection}>التطبيق الهاتفي التعلمي للمرشحين، هو الجزء الخاص بشق التكوين النظري في تعليم السياقة بمنظومة AEV 
                      المتكاملة حيث يلعب دور الوسيط للربط بشكل رقمي بين 
                      المرشح و مؤسسة تعليم السياقة من أجل تمكين المدارس 
                      من تقديم تدريب عالي الجودة يدعم التعلم المخصص والتعلم 
                      عن بعد حيث يمكن لكل طالب بدء التعلم تتبع تقدمه من 
                      طرف المدرب وتحليل المهارات لكشف نِقَاط الضعف حسب 
                      التصنيفات  أو أو الإطلاع على جدولة الحصص باستخدام 
                      تطبيق الهاتف فقط
                  </p>
              </div>
            }
            </div>
            
          </div>
        </section>
        <section className={style.sec3Mobile} style={{display:"none"}}>
          <div className={style.recDiv}>
              <div class={style.appsection1Mobile + " col-span-2"}>
                <p>يمكنك أن تجدنا على</p>
                <h1>التطبيق التعليمي للمدرسة السياقة فقط في جيبك</h1>
              </div>

              <div class={"self-center"}>
                <div class={"relative flex justify-end flex-row-reverse	"}>
                  <a href="/"><img src={appStore}  style={{ height: "7vh" }} alt=" Logo" /></a>
                  <a href="/"><img src={playStore}  style={{ height: "7vh" }} alt=" Logo" /></a>
                </div>
              </div>

          </div>

          
        </section>


        <section class={style.downApp + " grid grid-cols-3"}>
        <div class="relative flex flex-col sm:flex-row sm:space-x-4 justify-center">
                    

                        <a href="https://play.google.com/store/apps/details?id=ma.aev.student" class={style.buyBtn} type="button">
                              <div class={"grid grid-cols-2 items-center"}>
                                <div class="justify-self-center">
                                  {/* <svg class={style.icondown} xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512"><title></title><path d="M256,32C132.26,32,32,132.26,32,256S132.26,480,256,480,480,379.74,480,256,379.74,32,256,32ZM171,353.89a15.48,15.48,0,0,1-13.46,7.65,14.91,14.91,0,0,1-7.86-2.16,15.48,15.48,0,0,1-5.6-21.21l15.29-25.42a8.73,8.73,0,0,1,7.54-4.3h2.26c11.09,0,18.85,6.67,21.11,13.13Zm129.45-50L200.32,304H133.77a15.46,15.46,0,0,1-15.51-16.15c.32-8.4,7.65-14.76,16-14.76h48.24l57.19-97.35h0l-18.52-31.55C217,137,218.85,127.52,226,123a15.57,15.57,0,0,1,21.87,5.17l9.9,16.91h.11l9.91-16.91A15.58,15.58,0,0,1,289.6,123c7.11,4.52,8.94,14,4.74,21.22l-18.52,31.55-18,30.69-39.09,66.66v.11h57.61c7.22,0,16.27,3.88,19.93,10.12l.32.65c3.23,5.49,5.06,9.26,5.06,14.75A13.82,13.82,0,0,1,300.48,303.92Zm77.75.11H351.09v.11l19.82,33.71a15.8,15.8,0,0,1-5.17,21.53,15.53,15.53,0,0,1-8.08,2.27A15.71,15.71,0,0,1,344.2,354l-29.29-49.86-18.2-31L273.23,233a38.35,38.35,0,0,1-.65-38c4.64-8.19,8.19-10.34,8.19-10.34L333,273h44.91c8.4,0,15.61,6.46,16,14.75A15.65,15.65,0,0,1,378.23,304Z" fill="white"></path></svg> */}
                                  {/* <svg class={style.icondown} viewBox="-1.5 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <title>apple [#173]</title> <desc>Created with Sketch.</desc> <defs> </defs> <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"> <g id="Dribbble-Light-Preview" transform="translate(-102.000000, -7439.000000)" fill="#000000"> <g id="icons" transform="translate(56.000000, 160.000000)"> <path d="M57.5708873,7282.19296 C58.2999598,7281.34797 58.7914012,7280.17098 58.6569121,7279 C57.6062792,7279.04 56.3352055,7279.67099 55.5818643,7280.51498 C54.905374,7281.26397 54.3148354,7282.46095 54.4735932,7283.60894 C55.6455696,7283.69593 56.8418148,7283.03894 57.5708873,7282.19296 M60.1989864,7289.62485 C60.2283111,7292.65181 62.9696641,7293.65879 63,7293.67179 C62.9777537,7293.74279 62.562152,7295.10677 61.5560117,7296.51675 C60.6853718,7297.73474 59.7823735,7298.94772 58.3596204,7298.97372 C56.9621472,7298.99872 56.5121648,7298.17973 54.9134635,7298.17973 C53.3157735,7298.17973 52.8162425,7298.94772 51.4935978,7298.99872 C50.1203933,7299.04772 49.0738052,7297.68074 48.197098,7296.46676 C46.4032359,7293.98379 45.0330649,7289.44985 46.8734421,7286.3899 C47.7875635,7284.87092 49.4206455,7283.90793 51.1942837,7283.88393 C52.5422083,7283.85893 53.8153044,7284.75292 54.6394294,7284.75292 C55.4635543,7284.75292 57.0106846,7283.67793 58.6366882,7283.83593 C59.3172232,7283.86293 61.2283842,7284.09893 62.4549652,7285.8199 C62.355868,7285.8789 60.1747177,7287.09489 60.1989864,7289.62485" id="apple-[#173]"> </path> </g> </g> </g> </g></svg> */}
                                  <svg class={style.icondown} width="512" height="512"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496.255 608.728"><path d="M273.81 52.973C313.806.257 369.41 0 369.41 0s8.271 49.562-31.463 97.306c-42.426 50.98-90.649 42.638-90.649 42.638s-9.055-40.094 26.512-86.971zM252.385 174.662c20.576 0 58.764-28.284 108.471-28.284 85.562 0 119.222 60.883 119.222 60.883s-65.833 33.659-65.833 115.331c0 92.133 82.01 123.885 82.01 123.885s-57.328 161.357-134.762 161.357c-35.565 0-63.215-23.967-100.688-23.967-38.188 0-76.084 24.861-100.766 24.861C89.33 608.73 0 455.666 0 332.628c0-121.052 75.612-184.554 146.533-184.554 46.105 0 81.883 26.588 105.852 26.588z" fill="#fff"/></svg>
                                  
                                  </div>
                                <div>
                                  <span class={style.iconDown1Span}>GOT IT ON</span>
                                  <br></br>
                                  <span class={style.iconDown2Span}>Apple Store</span>
                                </div>
                              </div> 

                        </a>
                        <a href="https://play.google.com/store/apps/details?id=ma.aev.student" class={style.buyBtn} type="button">
                                  <div class={"grid grid-cols-2 items-center"}>
                                    <div class="justify-self-center"><svg class={style.icondown}  xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512"><title></title><path d="M48,59.49v393a4.33,4.33,0,0,0,7.37,3.07L260,256,55.37,56.42A4.33,4.33,0,0,0,48,59.49Z" fill="white"></path><path d="M345.8,174,89.22,32.64l-.16-.09c-4.42-2.4-8.62,3.58-5,7.06L285.19,231.93Z" fill="white"></path><path d="M84.08,472.39c-3.64,3.48.56,9.46,5,7.06l.16-.09L345.8,338l-60.61-57.95Z" fill="white"></path><path d="M449.38,231l-71.65-39.46L310.36,256l67.37,64.43L449.38,281C468.87,270.23,468.87,241.77,449.38,231Z" fill="white"></path></svg></div>
                                    <div>
                                      <span class={style.iconDown1Span}>GOT IT ON</span>
                                      <br></br>
                                      <span class={style.iconDown2Span}>Google Play</span>
                                    </div>
                                  </div> 

                        </a>

                      </div>

          <img src={path456} alt='path456' class={style.Path406}/>


          <div class={style.secGrid +" grid self-center"}>
            <span class={style.canYouText}>يمكنك أن تجدنا على</span>
            <span class={style.titleDownApp}>التطبيق التعليمي للمدرسة السياقة فقط في جيبك</span>


          </div>




        </section>



      {/* ----------------------------------SECTION4---------------------------------- */}
        <section className={style.sec4Desktop} style={{backgroundColor:"#f9f6ff", height:'83vh'}}>
            <div class={style.section4Header}>
                <h1>اكتشف كيف يمكن أن تساعدك في القيادة المدرسة السياقة خطوة بخطوة</h1>
            </div>

            <div class={"grid grid-cols-5"}>
                <div></div>
                <div style={{marginRight: '10px'}}>
                  <Link to="https://play.google.com/store/apps/details?id=ma.aev.student" type="button">
                    <div class={style.rec2}>
                          <span class={style.spanRec2}>AEV</span>
                          <span class={style.spanRec2}>التطبيق</span>
                          <img src={logo1}  alt="logo 1" class={style.Rectangle_177}/>
                      </div>
                          <a href="https://play.google.com/store/apps/details?id=ma.aev.student" class={"flex items-center justify-center"}>
                              <span class={style.rec1Explorer}>Explore</span>
                              <svg class={style.rec1Svg} viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path fill="#000000" d="M754.752 480H160a32 32 0 1 0 0 64h594.752L521.344 777.344a32 32 0 0 0 45.312 45.312l288-288a32 32 0 0 0 0-45.312l-288-288a32 32 0 1 0-45.312 45.312L754.752 480z"></path></g></svg>
                          </a>
                  </Link>    
                </div>

                <div style={{marginRight: '10px'}}>
                  <Link to="https://play.google.com/store/apps/details?id=ma.aev.student" type="button">
                    <div class={style.rec1}>
                        <span class={style.spanRec1}>AEV</span>
                        <span class={style.spanRec1}>المنصة </span>
                        {/* <div class={style.Rectangle_177}></div> */}
                        <img src={logo3}  alt="logo 3" class={style.Rectangle_177}/>

                    </div>
                    {/* <div class=flex items-center justify-center"> */}
                        <a href="https://play.google.com/store/apps/details?id=ma.aev.student" class={"flex items-center justify-center"}>
                            <span class={style.rec1Explorer}>Explore</span>
                            <svg class={style.rec1Svg} viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path fill="#000000" d="M754.752 480H160a32 32 0 1 0 0 64h594.752L521.344 777.344a32 32 0 0 0 45.312 45.312l288-288a32 32 0 0 0 0-45.312l-288-288a32 32 0 1 0-45.312 45.312L754.752 480z"></path></g></svg>
                        </a>
                    {/* </div> */}
                    </Link>
                </div>
                <div style={{marginRight: '10px'}}>
                  <Link to="https://play.google.com/store/apps/details?id=ma.aev.monitor" type="button">
                  <div class={style.rec2}>
                          <span class={style.spanRec2}>AEV</span>
                          <span class={style.spanRec2}>المحاكي</span>
        
                            <img src={logo2}  alt="logo 2" class={style.Rectangle_177}/>

                      </div>
                          <a href="https://play.google.com/store/apps/details?id=ma.aev.monitor" class={"flex items-center justify-center"}>
                              <span class={style.rec1Explorer}>Explore</span>
                              <svg class={style.rec1Svg} viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path fill="#000000" d="M754.752 480H160a32 32 0 1 0 0 64h594.752L521.344 777.344a32 32 0 0 0 45.312 45.312l288-288a32 32 0 0 0 0-45.312l-288-288a32 32 0 1 0-45.312 45.312L754.752 480z"></path></g></svg>
                          </a>
                  </Link>
                </div>
                <div></div>
            </div>

        </section>

        <section className={style.sec4Mobile} style={{backgroundColor:"#f9f6ff", display:"none"}}>
        <div class={"flex justify-center"}>
                <div className={"m-2"}>
                    <div class={style.rec1Mobil}>
                        <span class={style.spanRec1Mobile}>AEV</span>
                        <br></br>
                        <span class={style.spanRec1Mobile}>المنصة</span>
                        <img src={logo1}  alt="logo 1" class={style.Rectangle_177Mobile}/>

                    </div>
                        <a href="https://play.google.com/store/apps/details?id=ma.aev.student" class={"flex items-center justify-center"}>
                            <span class={style.rec1ExplorerMobile}>Explore</span>
                            <svg class={style.rec1SvgMobile} viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path fill="#000000" d="M754.752 480H160a32 32 0 1 0 0 64h594.752L521.344 777.344a32 32 0 0 0 45.312 45.312l288-288a32 32 0 0 0 0-45.312l-288-288a32 32 0 1 0-45.312 45.312L754.752 480z"></path></g></svg>
                        </a>
                </div>
                <div className={"m-2"}>
                    <div class={style.rec2Mobil}>
                        <span class={style.spanRec1Mobile}>AEV</span>
                        <br></br>
                        <span class={style.spanRec1Mobile}>المنصة</span>
                        {/* <div class={style.Rectangle_177Mobile}></div> */}
                        <img src={logo2}  alt="logo 1" class={style.Rectangle_177Mobile}/>

                    </div>
                    <a href="https://play.google.com/store/apps/details?id=ma.aev.student" class={"flex items-center justify-center"} style={{margin: '0px 0px 9px 39px;',justifyContent: 'left'}}>
                            <span class={style.rec1ExplorerMobile}>Explore</span>
                            <svg class={style.rec1SvgMobile} viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path fill="#000000" d="M754.752 480H160a32 32 0 1 0 0 64h594.752L521.344 777.344a32 32 0 0 0 45.312 45.312l288-288a32 32 0 0 0 0-45.312l-288-288a32 32 0 1 0-45.312 45.312L754.752 480z"></path></g></svg>
                        </a>
                </div>
                <div className={"m-2"}>
                    <div class={style.rec1Mobil}>
                        <span class={style.spanRec1Mobile}>AEV</span>
                        <br></br>
                        <span class={style.spanRec1Mobile}>المنصة</span>
                        {/* <div class={style.Rectangle_177Mobile}></div> */}
                        <img src={logo3}  alt="logo 1" class={style.Rectangle_177Mobile}/>

                    </div>
                        <a href="https://play.google.com/store/apps/details?id=ma.aev.student" class={"flex items-center justify-center"}>
                            <span class={style.rec1ExplorerMobile}>Explore</span>
                            <svg class={style.rec1SvgMobile} viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path fill="#000000" d="M754.752 480H160a32 32 0 1 0 0 64h594.752L521.344 777.344a32 32 0 0 0 45.312 45.312l288-288a32 32 0 0 0 0-45.312l-288-288a32 32 0 1 0-45.312 45.312L754.752 480z"></path></g></svg>
                        </a>

                </div>
            </div>
        </section>
    
        

       


      </>

    );
}
  
export default Section1;