import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from '../Pages/homePage';
import AboutPage from '../Pages/aboutPage';
import ContactPage from '../Pages/contactPage';
import MapPage from '../Pages/MapPage';
import Nopage from '../components/noPage/noPage';

function RoutesPack() {
  return (
<BrowserRouter>
      <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/map" element={<MapPage />} />
          <Route path="*" element={<Nopage />} /> 
      </Routes>
    </BrowserRouter>
  );
}

export default RoutesPack;








// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(<App />);