import Header from '../partials/header/header2';
import Section1 from '../components/Map/Section1';
import style from '../components/Home.module.css'

/* ----------------------------------------------------------- */
import AboutSection2 from '../partials/footer/section2';
import Footer from '../partials/footer/Footer';

function App() {
  return (
    <div class="h-screen">
       
      <div className="App" class="section1">
        <div  class={style.divGlobalMApPage2} style={{backgroundColor:'#1f0d3c'}}>
          <Header />
          <Section1 />
          <div className="App" class="snap-align-none scroll-smooth mt-4" style={{backgroundColor:"#f9f6ff",padding: "59px 0 0 0",marginTop: "123px"}}>
        <AboutSection2 />
        <Footer />
      </div>  
        </div>

      </div>
      <div class=""></div>
    </div>
  );
}

export default App;
