
import { FaChevronDown } from 'react-icons/fa';
import style from './Header.module.css';
// import React, { useState } from "react";

import { useState } from 'react';
import logoNavpar from '../../assets/images/Section2/dd@3x.png'
import SologoNavpar from '../../assets/images/gggwhite.png';
import logoSolodd from '../../assets/images/logoSoloWhite.png'
import aevTextdd from '../../assets/images/logoww.png'

function Header() {

    const [showModal, setShowModal] = useState(false);
    async function MobileMenu() {
        setShowModal(!showModal)
    }

    const [HoverNavbar, setHoverNavbar] = useState(false);

    function setHoverNavbarIn() {
        setHoverNavbar(true)
    }

    function setHoverNavbarOut() {
        setHoverNavbar(false)
    }




    return (
        <>
        
        <header class={showModal ? "hidden " + style.header : "block " + style.header}  className='headerr' id='header2'>
            <nav class={"w-full z-30 top-30 py-1 px-4 lg:px-4"}>
            <div class={"w-full flex items-center justify-between mt-0 px-3 py-2"}>
                <label for="menu-toggle" class={style.labelNavbar + " cursor-pointer md:hidden block"} onClick={MobileMenu}>
                    <svg fill='#1f0d3c' class={"fill-current"} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                        <title>menu</title>
                        <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
                    </svg>
                </label>
                <input class={"hidden"} type="checkbox" id="menu-toggle"/>
                
                <div class={"hidden w-full order-3 md:order-1 " + style.menu} id="menu">
                    <nav class={"md:flex items-center justify-between text-base text-white pt-4 md:pt-0"}>
                    <ul class={"md:flex items-center justify-between text-base text-white pt-4 md:pt-0"}>

                        <li><a href="/" class={style.langFr + " inline-block no-underline text-lg py-2 px-4 lg:-ml-2 font-bold"}>FR</a></li>
                        <li><a href="/" class={style.langAr + " inline-block no-underline text-lg py-2 px-4 lg:-ml-2 font-bold"}>AR</a></li>
                        </ul>
                    </nav>
                    <nav>
                        <ul class={style.ullist + " md:flex items-center justify-between text-base text-white pt-4 md:pt-0"}>
                        <li className={style.liMar}><a href="/contact" class={style.list +" "+style.sec2 +" inline-block no-underline text-lg py-2 px-4 lg:-ml-2 font-bold"}> اتصل بنا</a></li>
                        <li className={style.liMar}><a href="/about" class={style.list +" "+style.sec2 +" inline-block no-underline text-lg py-2 px-4 lg:-ml-2 font-bold"}>AEV عن</a></li>
                        <li className={style.liMar}><a href="https://play.google.com/store/apps/details?id=ma.aev.monitor" class={style.list +" "+style.sec2 +" inline-block no-underline text-lg py-2 px-4 lg:-ml-2 font-bold"}>تحميل</a></li>
                        <li className={style.liMar}><a href="/map" class={style.list +" "+style.sec2 +" inline-block no-underline text-lg py-2 px-4 lg:-ml-2 font-bold"}>AEV خريطة</a></li>
                        <li className={style.liMar}><a href="/" class={style.list +" "+style.sec2 +" inline-block no-underline text-lg py-2 px-4 lg:-ml-2 font-bold flex items-center"} aria-current="page" onMouseOver={setHoverNavbarIn} onMouseOut={setHoverNavbarOut}> <div   class="flex items-center"><FaChevronDown class='w-4 mr-2'/> مميزات  </div>
                        {HoverNavbar ? (
                            <ul className={style.ulNavDropdown}>
                                <li className={style.selected}><a href="/#app" class={"inline-block no-underline"}>AEV App</a></li>
                                <hr></hr>
                                <li className={style.selected}><a href="/#platform" class={"inline-block no-underline"}>AEV Platform</a></li>
                                <hr></hr>
                                <li className={style.selected}><a href="/#simulator" class={"inline-block no-underline"}>AEV Simulator</a></li>
                            </ul>
                        ):null}
                        </a>
                        </li>
                        </ul>
                    </nav>
                </div>
                
                <div class={"order-2 md:order-3 flex flex-wrap items-center justify-end mr-0 md:mr-4"} id="nav-content">
                <div class={style.auth}>
                    <a href="/" class="" >
                        <div>
                            <div class="flex items-center">
                                <div class="mr-2">
                                    <img src={logoSolodd} alt="logo solo dd" class={style.logoSolodd +" mt-6 mb-2"}/>
                                </div>
                                <div>
                                    <img src={aevTextdd} alt="logo solo dd" class={style.aevTextdd + " mt-6 mb-2"}/>
                                </div>
                            </div>

                            {/* <img class={style.logoImg +" "+style.sec2+ " mt-6 mb-2"} alt="Logo" /> */}
                            <img class={style.SuLogo +" "+style.sec2+ " mr-3 mt-1"} alt="SuLogo" />
                        </div>
                    </a>
                    </div>
                </div>
            </div>
            </nav>
        </header>
        {showModal ? (
        <>
        <nav className={style.navMoile + " w-full shadow"}>
            <div className='grid grid-cols-2' style={{justifySelf:"center"}}>
                <div>
                    <button className={style.svgClose} onClick={MobileMenu}><svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path fill-rule="evenodd" clip-rule="evenodd" d="M19.207 6.207a1 1 0 0 0-1.414-1.414L12 10.586 6.207 4.793a1 1 0 0 0-1.414 1.414L10.586 12l-5.793 5.793a1 1 0 1 0 1.414 1.414L12 13.414l5.793 5.793a1 1 0 0 0 1.414-1.414L13.414 12l5.793-5.793z" fill="#fec868"></path></g></svg></button>
                </div>
                <div class="self-center" style={{textAlign: "-webkit-right"}}>
                    <img src={logoNavpar} alt="logoNavpar" className={style.imgLogo} />
                    <img src={SologoNavpar} alt="SologoNavpar" className={style.SoimgLogo} />
                </div>
            </div>
            <ul className='text-center' style={{margin: "50px 0 0 0"}}>
                <li className={style.liMar}><a href='/' className={style.list}><span>مميزات</span></a></li>
                <li className={style.liMar}><a href='/map' className={style.list}><span>AEV خريطة</span></a></li>
                <li className={style.liMar}><a href='https://play.google.com/store/apps/details?id=ma.aev.monitor' className={style.list}><span>تحميل</span></a></li>
                <li className={style.liMar}><a href='/about' className={style.list}><span>AEV عن</span></a></li>
                <li className={style.liMar}><a href='/contact' className={style.list}><span>اتصل بنا</span></a></li>
                <div className='grid grid-cols-2'>
                    <div style={{justifySelf: "end"}}><a href='/' className={style.list}><span>AR</span></a></div>
                    <div style={{justifySelf: "baseline"}}><a href='/' className={style.list} style={{color:'#fff'}}><span>FR</span></a></div>

                </div>
                
            </ul>





        </nav>

        </>
      ) : null}
    </>







    );
}
  
export default Header;