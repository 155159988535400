import Header from '../partials/header';
import Section1 from '../components/Contact/Section1';
/* ----------------------------------------------------------- */
import Footer from '../partials/footer/Footer';

import style from '../components/Home.module.css'



function App() {
  return (
    <div class="h-screen">
       
      <div className="App" class="section1">
        <div class={style.divGlobalHomePage2} style={{backgroundColor:'#f9f6ff94'}}>
          <Header />
          <Section1 />
          <Footer />
        </div>

      </div>
      {/* <div style={{backgroundColor:"#f9f6ff", margin: "27% 0 0 0"}}>
      </div> */}
      <div class=""></div>
    </div>
  );
}

export default App;
