import { useState, useEffect, useRef  } from 'react';
import { FaPlay } from 'react-icons/fa';

/* --------------------------Import styles----------------------------- */
import style from './Style/Section1.module.css';

/* -------------------------Images------------------------------------- */
import phone1 from '../../../assets/images/phone1.png';
import phone2 from '../../../assets/images/phone2.png';
import phone3 from '../../../assets/images/phone4.png';
import mobilePhone from '../../../assets/images/phone1.png';

function Section1() {
  /* --------------------USE REF--------------------- */
  const section1 = useRef(null);

  /* --------------------USE STATE--------------------- */
  const [isVisibleSection1, setIsVisibleSection1] = useState(false);

  /* --------------------USE Effects--------------------- */
  useEffect(() => {
    const observer1 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisibleSection1(true);
          observer1.unobserve(entry.target);
        }
      });
    });
    const currentSection = section1.current;
    if (currentSection) {
      observer1.observe(currentSection);
    }

    return () => {
      if (currentSection) {
        observer1.unobserve(currentSection);
      }
    };
  }, []);  

  return (
    <>
      {/* ---------------------------------SECTION 1 ------------------------------------- */}
      <section ref={section1} class={`${isVisibleSection1 ? style.sectionLaptop : style.section2LaptopVeisuble}`}>
        <div class={style.gridSection1 + " grid grid-cols-2" }>
          <div>
            <div class="w-full">
              <div class={style.images}>
                <div class={style.parent}>
                  <img class={style.image3} alt="img2" src={phone3} />
                  <img class={style.image2} alt="img2" src={phone2} />
                  <img class={style.image1} alt="imafe1" src={phone1} />
                </div>
              </div>
            </div>
          </div>
          <div class="flex items-center">
            <div class="w-full">
              <div class={style.divH}>
                <div class={style.paddingTitle+" mt-12 flex items-center text-end place-content-end"}>
                  <div class={style.titleHeader}>قم بتشغيل الفيديو لاستكشاف المستقبل</div>
                  <a href='https://www.youtube.com/' class={style.Ellipse}>
                    <div class={style.Ellipse6}>
                      <FaPlay fill='#1f0d3c' />
                    </div>
                  </a>
                </div>
                <div class={style.paddingTitle + " w-full md:space-y-4 lg:space-y-8 xl:space-y-9 sm:pr-6 lg:pr-0 md:pb-0 pr-5"
                  }>
                  <h1 class={style.h1Title + " text-end font-bold" }>
                    <span class="font-extrabold">مستقبل AEV</span> <br></br>
                    <span class="font-extrabold">مدرسة السياقة</span>
                  </h1>
                  <p class={style.paragraph + " mx-auto text-end " }>لا تضيّع المزيد من الوقت, فنحن نضع تحت تصرفك أفضل
                    الأدوات <br />و أحسن التقنيات المتواجدة في مجال المدرسة السياقة </p>
                  <div class="relative flex flex-col sm:flex-row sm:space-x-4 justify-end">
                    <a href="https://play.google.com/store/apps/details?id=ma.aev.student" class={style.buyBtn} type="button">
                      <div class={"grid grid-cols-2 items-center"}>
                        <div class="justify-self-center"><svg class={style.icondown} xmlns="http://www.w3.org/2000/svg"
                            width="512" height="512" viewBox="0 0 512 512">
                            <title></title>
                            <path d="M48,59.49v393a4.33,4.33,0,0,0,7.37,3.07L260,256,55.37,56.42A4.33,4.33,0,0,0,48,59.49Z"
                              fill="white"></path>
                            <path d="M345.8,174,89.22,32.64l-.16-.09c-4.42-2.4-8.62,3.58-5,7.06L285.19,231.93Z" fill="white">
                            </path>
                            <path d="M84.08,472.39c-3.64,3.48.56,9.46,5,7.06l.16-.09L345.8,338l-60.61-57.95Z" fill="white">
                            </path>
                            <path
                              d="M449.38,231l-71.65-39.46L310.36,256l67.37,64.43L449.38,281C468.87,270.23,468.87,241.77,449.38,231Z"
                              fill="white"></path>
                          </svg></div>
                        <div>
                          <span class={style.iconDown1Span}>GOT IT ON</span>
                          <br></br>
                          <span class={style.iconDown2Span}>Google Play</span>
                        </div>
                      </div>
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=ma.aev.student" class={style.buyBtn} type="button">
                      <div class={"grid grid-cols-2 items-center"}>
                        <div class="justify-self-center"><svg class={style.icondown} xmlns="http://www.w3.org/2000/svg"
                            width="512" height="512" viewBox="0 0 512 512">
                            <title></title>
                            <path
                              d="M256,32C132.26,32,32,132.26,32,256S132.26,480,256,480,480,379.74,480,256,379.74,32,256,32ZM171,353.89a15.48,15.48,0,0,1-13.46,7.65,14.91,14.91,0,0,1-7.86-2.16,15.48,15.48,0,0,1-5.6-21.21l15.29-25.42a8.73,8.73,0,0,1,7.54-4.3h2.26c11.09,0,18.85,6.67,21.11,13.13Zm129.45-50L200.32,304H133.77a15.46,15.46,0,0,1-15.51-16.15c.32-8.4,7.65-14.76,16-14.76h48.24l57.19-97.35h0l-18.52-31.55C217,137,218.85,127.52,226,123a15.57,15.57,0,0,1,21.87,5.17l9.9,16.91h.11l9.91-16.91A15.58,15.58,0,0,1,289.6,123c7.11,4.52,8.94,14,4.74,21.22l-18.52,31.55-18,30.69-39.09,66.66v.11h57.61c7.22,0,16.27,3.88,19.93,10.12l.32.65c3.23,5.49,5.06,9.26,5.06,14.75A13.82,13.82,0,0,1,300.48,303.92Zm77.75.11H351.09v.11l19.82,33.71a15.8,15.8,0,0,1-5.17,21.53,15.53,15.53,0,0,1-8.08,2.27A15.71,15.71,0,0,1,344.2,354l-29.29-49.86-18.2-31L273.23,233a38.35,38.35,0,0,1-.65-38c4.64-8.19,8.19-10.34,8.19-10.34L333,273h44.91c8.4,0,15.61,6.46,16,14.75A15.65,15.65,0,0,1,378.23,304Z"
                              fill="white"></path>
                          </svg></div>
                        <div>
                          <span class={style.iconDown1Span}>GOT IT ON</span>
                          <br></br>
                          <span class={style.iconDown2Span}>Apple Store</span>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class={style.sectionMobile} style={{display:"none"}}>
        <div>
          <div>
            <div class='grid items-center justify-center justify-items-center	 mx-4'>
              <h1 class={style.H1Section1Mobile}>مستقبل AEV <br></br> مدرسة السياقة</h1>
              <p class={style.pSection1Mobile}>لا تضيّع المزيد من الوقت, فنحن نضع تحت تصرفك أفضل الأدوات و أحسن التقنيات
                المتواجدة في مجال المدرسة السياقة</p>
            </div>
            <div class={style.paddingTitle+" flex items-center justify-center justify-items-center mx-4"}>
              <div class={style.titleHeader}>قم بتشغيل الفيديو لاستكشاف المستقبل</div>
              <a href='https://www.youtube.com/' class={style.Ellipse}>
                <div class={style.Ellipse6}>
                  <FaPlay fill='#1f0d3c' />
                </div>
              </a>
            </div>
          </div>
          <div class={"flex justify-center"}>
            <img class={style.imageMobile} src={mobilePhone} alt='phone 1' />
          </div>
        </div>
        <div class={style.fix + " flex justify-center"}>
          <a href="https://play.google.com/store/apps/details?id=ma.aev.student" class={style.downloadBtnPlayStoreMobile}
            type="button">
            <div class={"grid grid-cols-2 items-center"}>
              <div class="justify-self-center">
                <svg class={style.icondownMobile + " bi bi-apple" } xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                  fill="currentColor" viewBox="0 0 16 16">
                  <path
                    d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516.024.034 1.52.087 2.475-1.258.955-1.345.762-2.391.728-2.43zm3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422.212-2.189 1.675-2.789 1.698-2.854.023-.065-.597-.79-1.254-1.157a3.692 3.692 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56.244.729.625 1.924 1.273 2.796.576.984 1.34 1.667 1.659 1.899.319.232 1.219.386 1.843.067.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758.347-.79.505-1.217.473-1.282z" />
                  <path
                    d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516.024.034 1.52.087 2.475-1.258.955-1.345.762-2.391.728-2.43zm3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422.212-2.189 1.675-2.789 1.698-2.854.023-.065-.597-.79-1.254-1.157a3.692 3.692 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56.244.729.625 1.924 1.273 2.796.576.984 1.34 1.667 1.659 1.899.319.232 1.219.386 1.843.067.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758.347-.79.505-1.217.473-1.282z" />
                </svg>

              </div>
              <div>
                <span class={style.iconDown1SpanMobile}>Download on</span>
                <br></br>
                <span class={style.iconDown2SpanMobile}>App Store</span>
              </div>
            </div>
          </a>

          <a href="https://play.google.com/store/apps/details?id=ma.aev.student" class={style.downloadBtnPlayStoreMobile}
            type="button">
            <div class={"grid grid-cols-2 items-center"}>
              <div class="justify-self-center"><svg class={style.icondownMobile} xmlns="http://www.w3.org/2000/svg"
                  width="512" height="512" viewBox="0 0 512 512">
                  <title></title>
                  <path d="M48,59.49v393a4.33,4.33,0,0,0,7.37,3.07L260,256,55.37,56.42A4.33,4.33,0,0,0,48,59.49Z"
                    fill="white"></path>
                  <path d="M345.8,174,89.22,32.64l-.16-.09c-4.42-2.4-8.62,3.58-5,7.06L285.19,231.93Z" fill="white">
                  </path>
                  <path d="M84.08,472.39c-3.64,3.48.56,9.46,5,7.06l.16-.09L345.8,338l-60.61-57.95Z" fill="white"></path>
                  <path d="M449.38,231l-71.65-39.46L310.36,256l67.37,64.43L449.38,281C468.87,270.23,468.87,241.77,449.38,231Z"
                    fill="white"></path>
                </svg></div>
              <div>
                <span class={style.iconDown1SpanMobile}>GET IT ON</span>
                <br></br>
                <span class={style.iconDown2SpanMobile}>Google Play</span>
              </div>
            </div>
          </a>

        </div>
      </section>
    </>
  );
}
export default Section1;