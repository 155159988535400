import style from './Style/Section1.module.css'
import { useLocation } from "react-router";
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

function Section1() {
  let data = useLocation();
  const [comment, setComment] = React.useState(data.state ? data.state.email: "");
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    // change this
    emailjs.sendForm('service_126cfrh', 'template_1n823sa', form.current, 'zQXnsn0rvt7iQe0q5')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
      <>
        <section class={style.sectionLaptop}>
          <div class={" grid grid-cols-2 gap-2"}>
            <div>
                <div class={"w-full"}>
                  <div class={style.formDiv}>
                    <h1 class={style.sendUsTitlte}>أرسل لنا رسالة</h1>
                    <form ref={form} onSubmit={sendEmail}>
                      <div>
                        <input name="user_name" type="text" placeholder='الاسم الكامل' class={style.inputFullname} />
                      </div>
                      <div>
                        {
                          data.state ? 
                          <input type="email" name="user_email" value={comment} onChange={event => {
                            setComment(
                              event.target.value
                            );
                          }} placeholder='البريد الإلكتروني' class={style.inpputEmail} />
                          :
                          <input type="email" name="user_email" placeholder='البريد الإلكتروني' class={style.inpputEmail} />
                        }
                      </div>
                      <div>
                        <input name="message" type="text" placeholder='الموضوع' class={style.inpputSubject} />
                      </div>
                      <div>
                        <textarea class={style.inpputMessage} placeholder='رسالتك هنا'  name="w3review" rows="4" cols="50" />
                      </div>

                      <div>
                        <button type='submit' class={style.submitBtn} >أرسل</button>
                      </div>
                    </form>

                  </div>
                </div>
            </div>

            <div>
                <div class={"w-full"}>
                    <div class={style.paddingTitle}>
                      <p class={style.callUs}>اتصل بنا</p>
                      <h1 class={style.h1Title + " text-end font-bold"}>
                        <span class={"font-extrabold"}>نحن هنا من <br></br> أجلك؟</span>
                      </h1>
                    </div>
                </div>
            </div>
          </div>
          <div class={style.contactin+" pb-6 mt-8"}>
            <div class={style.contactInfoDiv + " grid grid-cols-3"}>
              <a href='mailto:contact@aev.ma' class={style.contact1}>
                <div class={"flex items-center"}>
                  <p class={style.contacParagraph}>: البريد الإلكتروني </p>
                  <svg class={style.svgContact} fill="#1f0d3c" viewBox="0 0 1920 1920" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M1920 428.266v1189.54l-464.16-580.146-88.203 70.585 468.679 585.904H83.684l468.679-585.904-88.202-70.585L0 1617.805V428.265l959.944 832.441L1920 428.266ZM1919.932 226v52.627l-959.943 832.44L.045 278.628V226h1919.887Z" fill-rule="evenodd"></path> </g></svg>
                </div>
                <span class={style.contactaevma}>
                  contact@aev.ma
                </span>
              </a>
              <a href='tel:+212(05)64287609' class={style.contact1}>
                <div class={"flex items-center"}>
                  <p class={style.contacParagraph}>: رقم هاتف</p>
                  <svg class={style.svgContact} fill="#1f0d3c" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <title>phone</title> <path d="M0 10.375c0 0.938 0.344 1.969 0.781 3.063s1 2.125 1.438 2.906c1.188 2.063 2.719 4.094 4.469 5.781s3.813 3.094 6.125 3.938c1.344 0.531 2.688 1.125 4.188 1.125 0.75 0 1.813-0.281 2.781-0.688 0.938-0.406 1.781-1.031 2.094-1.781 0.125-0.281 0.281-0.656 0.375-1.094 0.094-0.406 0.156-0.813 0.156-1.094 0-0.156 0-0.313-0.031-0.344-0.094-0.188-0.313-0.344-0.563-0.5-0.563-0.281-0.656-0.375-1.5-0.875-0.875-0.5-1.781-1.063-2.563-1.469-0.375-0.219-0.625-0.313-0.719-0.313-0.5 0-1.125 0.688-1.656 1.438-0.563 0.75-1.188 1.438-1.625 1.438-0.219 0-0.438-0.094-0.688-0.25s-0.5-0.281-0.656-0.375c-2.75-1.563-4.594-3.406-6.156-6.125-0.188-0.313-0.625-0.969-0.625-1.313 0-0.406 0.563-0.875 1.125-1.375 0.531-0.469 1.094-1.031 1.094-1.719 0-0.094-0.063-0.375-0.188-0.781-0.281-0.813-0.656-1.75-0.969-2.656-0.156-0.438-0.281-0.75-0.313-0.906-0.063-0.094-0.094-0.219-0.125-0.375s-0.094-0.281-0.125-0.406c-0.094-0.281-0.25-0.5-0.406-0.625-0.156-0.063-0.531-0.156-0.906-0.188-0.375 0-0.813-0.031-1-0.031-0.094 0-0.219 0-0.344 0.031h-0.406c-1 0.438-1.719 1.313-2.25 2.344-0.5 1.031-0.813 2.188-0.813 3.219z"></path> </g></svg>
                </div>
                <span class={style.contactaevma2}>
                +212(05)64287609
                </span>
              </a>
              <a href='http://maps.google.com/?q=1 departement N°265 centre A2' class={style.contact1}>
                <div class={"flex items-center"}>
                  <p class={style.contacParagraph}>: العنوان</p>
                  <svg class={style.svgContact} fill="#1f0d3c" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M12,2a8,8,0,0,0-7.992,8A12.816,12.816,0,0,0,12,22v0H12v0a12.816,12.816,0,0,0,7.988-12A8,8,0,0,0,12,2Zm0,11a3,3,0,1,1,3-3A3,3,0,0,1,12,13Z"></path></g></svg>
                </div>
                <span class={style.contactaevma3}>
                Addresse 1 departement N°265 centre A2
                </span>
              </a>
            </div>
          </div>
        </section>
        <section class={style.sectionMobile} style={{display:"none"}}>
          <div class={" grid-cols-2 gap-2 flex flex-wrap-reverse	justify-center"}>
            
            <div style={{textAlign: "-webkit-center"}}>
                  <div class={"w-full"}>
                    <div class={style.formDivMobile}>
                      <h1 class={style.sendUsTitlteMobile}>أرسل لنا رسالة</h1>
                      <form ref={form} onSubmit={sendEmail}>
                        <div>
                          <input name="user_name" type="text" placeholder='الاسم الكامل' class={style.inputFullnameMobile} />
                        </div>
                        <div>
                        {
                          data.state ? 
                          <input  name="user_email" type="email" value={comment} onChange={event => {
                            setComment(
                              event.target.value
                            );
                          }} placeholder='البريد الإلكتروني' class={style.inpputEmailMobile} />
                          :
                          <input name="user_email" type="email" placeholder='البريد الإلكتروني' class={style.inpputEmailMobile} />
                        }
                        </div>
                        <div>
                          <input  name="message" type="text" placeholder='الموضوع' class={style.inpputSubjectMobile} />
                        </div>
                        <div>
                          <textarea class={style.inpputMessageMobile} placeholder='رسالتك هنا'  name="w3review" rows="4" cols="50" />
                        </div>

                        <div>
                          <button type='submit' class={style.submitBtnMobile} >أرسل</button>
                        </div>
                      </form>

                    </div>
                  </div>
            </div>
            <div>
              <div class={"w-full"}>
                <div class={style.paddingTitle}>
                  <p class={style.callUsMobile}>اتصل بنا</p>
                  <h1 class={style.h1TitleMobile + " text-end font-bold"}>
                    <span class={"font-extrabold"}>نحن هنا من <br></br> أجلك؟</span>
                  </h1>
                </div>
              </div>
            </div>
          </div>

          
          <div style={{textAlign: "-webkit-center"}} class="mt-8">

          
            <div className={style.divContactMobile}>
                <div class={"flex items-center"}>
                  <p class={style.contacParagraph}>: البريد الإلكتروني </p>
                  <svg class={style.svgContact} fill="#1f0d3c" viewBox="0 0 1920 1920" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M1920 428.266v1189.54l-464.16-580.146-88.203 70.585 468.679 585.904H83.684l468.679-585.904-88.202-70.585L0 1617.805V428.265l959.944 832.441L1920 428.266ZM1919.932 226v52.627l-959.943 832.44L.045 278.628V226h1919.887Z" fill-rule="evenodd"></path> </g></svg>
                </div>
                <span class={style.contactaevma}>
                  contact@aev.ma
                </span>
            </div>
            <div className={style.divContactMobile}>
              <div class={"flex items-center"}>
                  <p class={style.contacParagraph}>: رقم هاتف</p>
                  <svg class={style.svgContact} fill="#1f0d3c" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <title>phone</title> <path d="M0 10.375c0 0.938 0.344 1.969 0.781 3.063s1 2.125 1.438 2.906c1.188 2.063 2.719 4.094 4.469 5.781s3.813 3.094 6.125 3.938c1.344 0.531 2.688 1.125 4.188 1.125 0.75 0 1.813-0.281 2.781-0.688 0.938-0.406 1.781-1.031 2.094-1.781 0.125-0.281 0.281-0.656 0.375-1.094 0.094-0.406 0.156-0.813 0.156-1.094 0-0.156 0-0.313-0.031-0.344-0.094-0.188-0.313-0.344-0.563-0.5-0.563-0.281-0.656-0.375-1.5-0.875-0.875-0.5-1.781-1.063-2.563-1.469-0.375-0.219-0.625-0.313-0.719-0.313-0.5 0-1.125 0.688-1.656 1.438-0.563 0.75-1.188 1.438-1.625 1.438-0.219 0-0.438-0.094-0.688-0.25s-0.5-0.281-0.656-0.375c-2.75-1.563-4.594-3.406-6.156-6.125-0.188-0.313-0.625-0.969-0.625-1.313 0-0.406 0.563-0.875 1.125-1.375 0.531-0.469 1.094-1.031 1.094-1.719 0-0.094-0.063-0.375-0.188-0.781-0.281-0.813-0.656-1.75-0.969-2.656-0.156-0.438-0.281-0.75-0.313-0.906-0.063-0.094-0.094-0.219-0.125-0.375s-0.094-0.281-0.125-0.406c-0.094-0.281-0.25-0.5-0.406-0.625-0.156-0.063-0.531-0.156-0.906-0.188-0.375 0-0.813-0.031-1-0.031-0.094 0-0.219 0-0.344 0.031h-0.406c-1 0.438-1.719 1.313-2.25 2.344-0.5 1.031-0.813 2.188-0.813 3.219z"></path> </g></svg>
                </div>
                <span class={style.contactaevma2}>
                +212(05)64287609
                </span>
            </div>
            <div className={style.divContactMobile}>
              <div class={"flex items-center"}>
                  <p class={style.contacParagraph}>: العنوان</p>
                  <svg class={style.svgContact} fill="#1f0d3c" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M12,2a8,8,0,0,0-7.992,8A12.816,12.816,0,0,0,12,22v0H12v0a12.816,12.816,0,0,0,7.988-12A8,8,0,0,0,12,2Zm0,11a3,3,0,1,1,3-3A3,3,0,0,1,12,13Z"></path></g></svg>
                </div>
                <span class={style.contactaevma3}>
                Addresse 1 departement N°265 centre A2
                </span>
            </div>

          </div>


        </section>
      </>

    );
}
  
export default Section1;